import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./style.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import { ToastContainer, toast } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css';
 import logo from "../../../src/images/logo.png"
 import Modal from '../../Comps/Modal';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { apiUrl } from '../Config';
import Slider from '../../Comps/Slider';
import InputMask from 'react-input-mask'
class Page404 extends React.Component {
   constructor(props) {
      super(props);
     
      this.state = {
     Loader:true,
     selectedButtons: [],
     manualSlc:10,
     imagem:[]
      }  
   }

componentDidMount(){
    document.body.style.backgroundColor = '#f8f8fa';
    document.title = "Carregando..."
   var url = window.location.href;
   var pastaAtual = url.substring(url.lastIndexOf("/") + 1);
   fetch(`${apiUrl}/raffles/list/`+pastaAtual, {
      method: 'GET',
      credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
      headers: {
        'Authorization': 'Bearer '+Cookies.get('auth_user'), // Se necessário, adicione um cabeçalho de autorização
        'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
      }
    })
    .then(response => {
      if (response.status == 404) {
    
         this.setState({err:true})
   
      }
    
  
      return response.json();
   
    }).then(result => {
      if(result.status == true){
         document.title = result.title;
         this.setState({data:result,Loader:false,err:false}) 

     
         if(result.imagem.length > 0){
            var Object = [];
            result.imagem.forEach((row) => {
            Object.push({
               image:`${apiUrl}/static/${row.name}`
            })
            })
            this.setState({imagem:Object})
         }else{
            this.setState({imagem:[]})
         }



      }else{
         document.title = "Página não encontrada!"
         this.setState({data:result,Loader:false,err:true}) 
      }
    
      })
      .catch(error => {
    console.log(error)   
    });
  
}

handleClick = (index) => {
   this.setState((prevState) => {
     if (prevState.selectedButtons.includes(index)) {
       return {
         selectedButtons: prevState.selectedButtons.filter(
           (buttonIndex) => buttonIndex !== index
         ),
       };
     } else {
       return {
         selectedButtons: [...prevState.selectedButtons, index],
       };
     }
   });
 };

  render() {
   if(this.state.Loader){
      return(<>
         <div class="sc-179422ed-0"  style={{ display: 'flex', alignItems: 'center', height: '100vh',justifyContent:'center' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       </div>
      </>)
   }
    return (
 <>

 {this.state.err == true ? <>
  
   <main class="sc-de8cbe2d-0 iypOTp">
   <div class="sc-de8cbe2d-1 hCroVH">
      <div class="sc-de8cbe2d-3 kXRTMm">
         <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="currentColor" viewBox="0 0 1024 1024">
            <defs>
               <linearGradient id="_element_656" gradientUnits="userSpaceOnUse" x1="512" y1="1024.079" x2="512" y2="-0.079">
                  <stop offset="0" stop-color="#fb8b00"></stop>
                  <stop offset="1" stop-color="#ffc47b"></stop>
               </linearGradient>
               <linearGradient id="_element_464" gradientUnits="userSpaceOnUse" x1="109.066" y1="348.433" x2="109.066" y2="146.828">
                  <stop offset="0" stop-color="#0070fb"></stop>
                  <stop offset="1" stop-color="#7acbff"></stop>
               </linearGradient>
            </defs>
            <path fill="url(#_element_656)" d="M379.48 17.45C652.62 -55.74 933.37 106.35 1006.55 379.48C1079.74 652.62 917.65 933.37 644.52 1006.55C371.38 1079.74 90.63 917.65 17.45 644.52C-55.74 371.38 106.35 90.63 379.48 17.45Z"></path>
            <path fill="none" stroke="#000000" stroke-linecap="round" stroke-width="57" d="M244.46 599.22C244.46 599.22 235.86 545.62 295.2 529.72C353.01 514.23 371.96 565.05 371.96 565.05"></path>
            <path fill="none" stroke="#000000" stroke-linecap="round" stroke-width="57" d="M659.81 487.92C659.81 487.92 651.21 434.32 710.55 418.42C768.36 402.93 787.31 453.76 787.31 453.76"></path>
            <path fill="#000000" d="M557.55 682C614.17 666.83 649.47 705.85 644.68 713.53C641.24 719.05 610.88 700.84 565.83 712.91C524.32 724.04 505.89 756.33 498.83 752.61C490.38 748.16 500.71 697.23 557.55 682Z"></path>
            <path fill="url(#_element_464)" d="M99.54 156.59C158.71 190.76 249.14 310.84 126.3 343.75C2.57 376.91 22.48 225.67 56.42 166.88C70.38 142.7 73.95 141.82 99.54 156.59Z"></path>
         </svg>
      </div>
      <div class="sc-de8cbe2d-2 bAAQGt">
         <h1 class="sc-de8cbe2d-4 hZNJuz">Esta página não existe</h1>
         <p class="sc-de8cbe2d-5 kPXVax">Desculpe, a página que você está procurando não existe ou foi movida.</p>
      </div>
      <div class="sc-de8cbe2d-6 BJfFs">
    <Link to="/" class="sc-688eee80-0 ivGZii">Retornar ao início</Link></div>
   </div>
</main>

 </> : <>
 
 <header class="sc-d15d4f87-0 fMFqRX header-nav-fixed">
   <nav aria-label="Menu principal" class="sc-d15d4f87-1 gCnxGZ">
      <div class="sc-d15d4f87-2 deffc header-mobile-menu">
         <header></header>
         <ul class="navigation">
            <li class="navigation-item">
               <button type="button" aria-label="Ver meus bilhetes">
                  <div>
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart ">
                        <circle cx="8" cy="21" r="1"></circle>
                        <circle cx="19" cy="21" r="1"></circle>
                        <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
                     </svg>
                  </div>
                  <span>Ver meus bilhetes</span>
               </button>
            </li>
           
            <li class="navigation-item">
               <a href={`https://wa.me/${this.state.data.by_created.phone}`} target="_blank" rel="noopener noreferrer" aria-label="Contato">
                  <div>
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
                     </svg>
                  </div>
                  <span>Contato</span>
               </a>
            </li>
         </ul>
         <div class="sc-d15d4f87-3 esZBWS">
         <a href={`whatsapp://send?text=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no WhatsApp" class="sc-84a33c3d-1 kzXqZS">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 24 24">
               <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
            </svg>
         </a>
         <a href={`https://facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Facebook" class="sc-84a33c3d-1 dXNPhl">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook ">
               <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
            </svg>
         </a>
         <a href={`https://t.me/share/url?url=${window.location.href}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Telegram" class="sc-84a33c3d-1 yDVKT">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
               <path d="m5.9 13.5l-5.4-1.7c0 0-0.7-0.3-0.5-0.9 0.1-0.1 0.2-0.2 0.4-0.4 1.2-0.8 22.3-8.4 22.3-8.4 0 0 0.6-0.2 0.9-0.1q0.1 0.1 0.1 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0 0.1 0 0.1 0 0.2 0 0 0 0.1 0 0 0 0.1c0 0.1 0 0.3 0 0.5-0.2 2-4 17.5-4 17.5 0 0-0.2 0.9-1 0.9q-0.2 0-0.3 0-0.2 0-0.3-0.1-0.2-0.1-0.3-0.1-0.1-0.1-0.2-0.2c-1.7-1.4-7.2-5.2-8.5-6q0 0 0 0 0 0 0-0.1 0 0 0 0-0.1 0-0.1 0c0-0.1 0.1-0.2 0.1-0.2 0 0 9.7-8.7 10-9.6 0 0-0.1-0.1-0.2 0-0.6 0.2-11.8 7.2-13 8q-0.1 0-0.1 0 0 0-0.1 0 0 0 0 0-0.1 0-0.1 0z"></path>
            </svg>
         </a>
         <a href={`https://twitter.com/share?url=${window.location.href}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Twitter" class="sc-84a33c3d-1 daTCjo">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-twitter ">
               <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
            </svg>
         </a>
         </div>
      </div>
      <div class="sc-d15d4f87-5 cUWbSa header-container">
         <div class="sc-d15d4f87-6 gvOTnf">
            {this.state.data.personalize.logo != null ? <>
            <Link to="/" class="sc-da21b912-7 dtDsgB">
            <img src={`${apiUrl}/static/${this.state.data.personalize.logo}`}  />
            </Link>
         
            </> : <>
            <Link class="txt" to="/">EuRifei</Link>
            </>}
           
            <button type="button" aria-label="Abrir menu" aria-expanded="false" aria-haspopup="true" class="sc-d15d4f87-8 iVhvGR">
               <div class="sc-d15d4f87-9 cvqGmm">
                  <div class="sc-d15d4f87-10 dpdcxm header-hamburguer-line"></div>
                  <div class="sc-d15d4f87-10 dpdcxm header-hamburguer-line"></div>
               </div>
            </button>
         </div>
         <ul data-orientation="horizontal" dir="ltr" class="sc-d15d4f87-11 dAWJrA">
        
            <li class="sc-d15d4f87-12 gXNzsT">
               <a href={`https://wa.me/${this.state.data.phone}`} target="_blank" rel="noopener noreferrer" aria-label="Contato" class="sc-d15d4f87-13 bSdike">
                  <div >
                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
                     </svg>
                  </div>
                  Contato
               </a>
            </li>
         </ul>
      </div>
   </nav>
</header>

<main class="sc-cc128809-0 bWYGIb">

<div class="sc-cc128809-2 hjOGch">
   
{this.state.data.personalize.slide_style == 0 ? <>

   <div class="sc-c4f18ec9-5 hFIskf row">
   
   <div class="sc-cc128809-11 jmPaym left-container">
      {this.state.imagem.length > 0 ? <>
         <Slider slides={this.state.imagem}/>
      </> : <>
      <div tabindex="-1" class="sc-681247da-3 cuQzMG">
      <img src="https://rifei.imgix.net/storage/images/no-image.jpg?w=1080&amp;fit=max&amp;q=70&amp;auto=format,compress" alt="Sem imagem" />
   
      </div>
         </>}
  
{Object.keys(this.state.data.description).length > 0  && <>
<div style={{height:32}}></div>
   <div class="sc-c4f18ec9-6 bdGoUT left-card">

   <div class="sc-c4f18ec9-8 flewlM">
      <small>Descrição:</small>
      <div class="sc-8e721470-0 jJuTYa">
         <div class="sc-8e721470-1 gaogiP overflow"  dangerouslySetInnerHTML={{ __html: this.state.data.description.content }}  >
        
         </div>
         
      </div>
   </div>
</div>

</>}


   </div>
   
   
   
   
   <div class="sc-cc128809-12 rpzcl right-card">
      <div class="sc-cc128809-8 ePQbcx">
         <h1 class="sc-cc128809-14 fCiQfM slide-default">{this.state.data.title}</h1>
         <div class="sc-cc128809-15 bEYJCt">
            <p>Por apenas</p>
            <div class="sc-cc128809-16 hJcryO">{Number(this.state.data.price).toLocaleString('pt-BR', {
       style: 'currency',
       currency: 'BRL',
     })}</div>
         </div>
      </div>
      {Object.values(this.state.data.socials).every(value => value === null) ? <>
         <div class="sc-cc128809-8 ePQbcx">
         <p class="sc-cc128809-18 eEzvHg">Compartilhar:</p>
         <div class="sc-84a33c3d-0 QXJpO">
            <a href={`whatsapp://send?text=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no WhatsApp" class="sc-84a33c3d-1 kzXqZS">
               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
               </svg>
            </a>
            <a href={`https://facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Facebook" class="sc-84a33c3d-1 dXNPhl">
               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook ">
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
               </svg>
            </a>
            <a href={`https://t.me/share/url?url=${window.location.href}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Telegram" class="sc-84a33c3d-1 yDVKT">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                  <path d="m5.9 13.5l-5.4-1.7c0 0-0.7-0.3-0.5-0.9 0.1-0.1 0.2-0.2 0.4-0.4 1.2-0.8 22.3-8.4 22.3-8.4 0 0 0.6-0.2 0.9-0.1q0.1 0.1 0.1 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0 0.1 0 0.1 0 0.2 0 0 0 0.1 0 0 0 0.1c0 0.1 0 0.3 0 0.5-0.2 2-4 17.5-4 17.5 0 0-0.2 0.9-1 0.9q-0.2 0-0.3 0-0.2 0-0.3-0.1-0.2-0.1-0.3-0.1-0.1-0.1-0.2-0.2c-1.7-1.4-7.2-5.2-8.5-6q0 0 0 0 0 0 0-0.1 0 0 0 0-0.1 0-0.1 0c0-0.1 0.1-0.2 0.1-0.2 0 0 9.7-8.7 10-9.6 0 0-0.1-0.1-0.2 0-0.6 0.2-11.8 7.2-13 8q-0.1 0-0.1 0 0 0-0.1 0 0 0 0 0-0.1 0-0.1 0z"></path>
               </svg>
            </a>
            <a href={`https://twitter.com/share?url=${window.location.href}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Twitter" class="sc-84a33c3d-1 daTCjo">
               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-twitter ">
                  <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
               </svg>
            </a>
         </div>
      </div>
      </> : <>
      <div class="sc-cc128809-8 ePQbcx">
         <p class="sc-cc128809-18 eEzvHg">Redes sociais:</p>
         <div class="sc-84a33c3d-0 QXJpO">
      
{this.state.data.socials.facebook != null && <>
<a href={`https://facebook.com/${this.state.data.socials.facebook}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Facebook" class="sc-84a33c3d-1 dXNPhl">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook ">
               <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
            </svg>
</a>
</>}



{this.state.data.socials.instagram != null && <>
   <a href={`https://instagram.com/${this.state.data.socials.instagram}`} target="_blank" rel="noopener noreferrer" aria-label="Instagram" class="sc-5122807c-1 cWDpth">
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-instagram ">
      <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
      <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
   </svg>
</a>

</>}

{this.state.data.socials.tiktok != null && <>
   <a href={`https://tiktok.com/@${this.state.data.socials.tiktok}`} target="_blank" rel="noopener noreferrer" aria-label="TikTok" className="sc-5122807c-1 dlVKxp2">
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" style={{ zIndex: 2 }}>
    <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#24f6f0" style={{ position: 'absolute', top: '8px', left: '8px', zIndex: 1 }}>
    <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fe2d52" style={{ position: 'absolute', bottom: '8px', right: '8px', zIndex: 0 }}>
    <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
  </svg>
</a>
</>}


{this.state.data.socials.ytb != null && <>
   <a href={`https://youtube.com/@${this.state.data.socials.ytb}`} target="_blank" rel="noopener noreferrer" aria-label="TikTok" className="sc-5122807c-1 cn-ytb">
   <svg height="800px" width="800px" version="1.1" id="Layer_1" viewBox="0 0 461.001 461.001"><g><path d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"></path></g></svg>
   </a>
</>}


      </div>
      </div>
      
      
      </> }
     

   </div>
   
   
   </div>

</> : <>

<Slider slides={this.state.imagem}/>
<div class="sc-c4f18ec9-1 lieBDi slide-large inf-default">
   <div class="sc-c4f18ec9-2 fEedXt">
      <div class="sc-c4f18ec9-5 hFIskf row">
         <div class="sc-c4f18ec9-11 qRJqr left-container">
            <div class="sc-c4f18ec9-6 bdGoUT left-card">
               <div class="sc-c4f18ec9-8 flewlM">
                  <h1 class="sc-c4f18ec9-14 dkbEqf">{this.state.data.title}</h1>
                  <div class="sc-c4f18ec9-15 lcuIKS">
                     <p>Por apenas</p>
                     <div class="sc-c4f18ec9-16 jBJMQn">{Number(this.state.data.price).toLocaleString('pt-BR', {
       style: 'currency',
       currency: 'BRL',
     })}</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="sc-c4f18ec9-12 jjqTSU right-card">
           {Object.values(this.state.data.socials).every(value => value === null) ? <>
            <div class="sc-c4f18ec9-8 flewlM">
               <p class="sc-c4f18ec9-18 ffsVIp">Compartilhar:</p>
               <div class="sc-5122807c-0 fiBZHc">
               <a href={`whatsapp://send?text=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no WhatsApp" class="sc-84a33c3d-1 kzXqZS">
               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
               </svg>
            </a>
            <a href={`https://facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Facebook" class="sc-84a33c3d-1 dXNPhl">
               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook ">
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
               </svg>
            </a>
            <a href={`https://t.me/share/url?url=${window.location.href}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Telegram" class="sc-84a33c3d-1 yDVKT">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                  <path d="m5.9 13.5l-5.4-1.7c0 0-0.7-0.3-0.5-0.9 0.1-0.1 0.2-0.2 0.4-0.4 1.2-0.8 22.3-8.4 22.3-8.4 0 0 0.6-0.2 0.9-0.1q0.1 0.1 0.1 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0 0.1 0 0.1 0 0.2 0 0 0 0.1 0 0 0 0.1c0 0.1 0 0.3 0 0.5-0.2 2-4 17.5-4 17.5 0 0-0.2 0.9-1 0.9q-0.2 0-0.3 0-0.2 0-0.3-0.1-0.2-0.1-0.3-0.1-0.1-0.1-0.2-0.2c-1.7-1.4-7.2-5.2-8.5-6q0 0 0 0 0 0 0-0.1 0 0 0 0-0.1 0-0.1 0c0-0.1 0.1-0.2 0.1-0.2 0 0 9.7-8.7 10-9.6 0 0-0.1-0.1-0.2 0-0.6 0.2-11.8 7.2-13 8q-0.1 0-0.1 0 0 0-0.1 0 0 0 0 0-0.1 0-0.1 0z"></path>
               </svg>
            </a>
            <a href={`https://twitter.com/share?url=${window.location.href}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Twitter" class="sc-84a33c3d-1 daTCjo">
               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-twitter ">
                  <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
               </svg>
            </a>
            </div>
            </div>
           </> : <>
           <div class="sc-c4f18ec9-8 flewlM">
               <p class="sc-c4f18ec9-18 ffsVIp">Redes sociais:</p>
               <div class="sc-5122807c-0 fiBZHc">

{this.state.data.socials.facebook != null && <>
<a href={`https://facebook.com/${this.state.data.socials.facebook}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Facebook" class="sc-84a33c3d-1 dXNPhl">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook ">
               <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
            </svg>
</a>
</>}



{this.state.data.socials.instagram != null && <>
   <a href={`https://instagram.com/${this.state.data.socials.instagram}`} target="_blank" rel="noopener noreferrer" aria-label="Instagram" class="sc-5122807c-1 cWDpth">
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-instagram ">
      <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
      <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
   </svg>
</a>

</>}

{this.state.data.socials.tiktok != null && <>
   <a href={`https://tiktok.com/@${this.state.data.socials.tiktok}`} target="_blank" rel="noopener noreferrer" aria-label="TikTok" className="sc-5122807c-1 dlVKxp2">
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" style={{ zIndex: 2 }}>
    <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#24f6f0" style={{ position: 'absolute', top: '8px', left: '8px', zIndex: 1 }}>
    <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fe2d52" style={{ position: 'absolute', bottom: '8px', right: '8px', zIndex: 0 }}>
    <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
  </svg>
</a>
</>}


{this.state.data.socials.ytb != null && <>
   <a href={`https://youtube.com/@${this.state.data.socials.ytb}`} target="_blank" rel="noopener noreferrer" aria-label="TikTok" className="sc-5122807c-1 cn-ytb">
   <svg height="800px" width="800px" version="1.1" id="Layer_1" viewBox="0 0 461.001 461.001"><g><path d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"></path></g></svg>
   </a>
</>}



        
               </div>
            </div>
           </>}
         

         </div>
      </div>
   </div>
</div>

</>}

</div>
<hr class="sc-cc128809-27 eZjpbQ"></hr>
<div class="OIUy7">
{this.state.data.show_numbers == 1 ? <>

<div class="sc-cc128809-2 hjOGch">
<div class="sc-e1a39f58-0 hnQXdV">
   <div class="sc-cc128809-13 fyFjux">
      <div>
         <div class="sc-cc128809-28 cxQIMN">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket ">
               <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path>
               <path d="M13 5v2"></path>
               <path d="M13 17v2"></path>
               <path d="M13 11v2"></path>
            </svg>
         </div>
      </div>
      <h1 class="sc-cc128809-14 fCiQfM">Bilhetes</h1>
   </div>
</div>
<p class="sc-e1a39f58-1 jbUbQM">Selecione abaixo quais bilhetes deseja ver</p>
</div>



<div class="sc-cc128809-2 hjOGch">
<div class="sc-e1a39f58-4 eleDfn">
   <button type="button" class="sc-e1a39f58-5 VTamF all" aria-label="Todos"><span>Todos</span><span class="sc-e1a39f58-6 gvLTZy all">{this.state.data.numbers}</span></button>
   <button type="button" class="sc-e1a39f58-5 VTamF available" aria-label="Disponíveis"><span>Disponíveis</span><span class="sc-e1a39f58-6 gvLTZy available">0</span></button>
   <button type="button" class="sc-e1a39f58-5 VTamF approved" aria-label="Pagos"><span>Pagos</span><span class="sc-e1a39f58-6 gvLTZy approved">0</span></button>
   <button type="button" class="sc-e1a39f58-5 VTamF in_process" aria-label="Reservados"><span>Reservados</span><span class="sc-e1a39f58-6 gvLTZy in_process">0</span></button>
   <button type="button" class="sc-e1a39f58-5 VTamF search-numbers" aria-label="Ver meus bilhetes" onClick={()=>{
 this.setState({buscN:true})

   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart ">
         <circle cx="8" cy="21" r="1"></circle>
         <circle cx="19" cy="21" r="1"></circle>
         <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
      </svg>
      <span> Ver meus bilhetes</span>
   </button>
</div>
</div>
<div id="numbers" class="sc-e1a39f58-7 kCqnlk">


{this.state.data.list.map((item,index)=><>

{item.status == "available" ? <>
<button className={this.state.selectedButtons.includes(index) ? 'sc-e1a39f58-8 gJdDMo ticket selected' : 'sc-e1a39f58-8 gJdDMo ticket'}  onClick={()=>{
   this.handleClick(index)
}}>{item.number}</button>

</> : <>
<button  className={`sc-e1a39f58-8 gJdDMo ticket ${item.status}`} onClick={()=>{
   Modal.create({titulo:`Bilhete #${item.number}`,html:<>
   <div class="sc-e1a39f58-17 cUeHHZ"><small>Participante:</small><p><b>{item.name}</b></p><small>Status:</small><p><b>{item.status == "approved" ? "Pago" : "Reservado"}</b></p></div>
   </>})
}}>{item.number}</button>
</>}

</>)}



</div>

{this.state.selectedButtons.length > 0 && <>
<div className="sc-e1a39f58-9 gYPbsO" style={{ transform: 'none' }}>
   <div className="sc-e1a39f58-10 gPeSQm">
     <div className="sc-e1a39f58-11 iQfOJV">
       <div className="sc-e1a39f58-13 bAHuwg">
         <div className="sc-e1a39f58-14 iTwJAS">
           <h2>
              {Number(this.state.selectedButtons.length * this.state.data.price).toLocaleString('pt-BR', {
 style: 'currency',
 currency: 'BRL',
})}
           </h2>
         </div>
       </div>
       <div className="sc-e1a39f58-16 hfCDto">
         <button type="button"  className="sc-688eee80-2 jVdzQX" onClick={()=>{
            this.setState({selectedButtons:[]})
         }}>
           Cancelar
         </button>
         <button
           type="button"
           id="checkout-button"
         
           aria-label="Participar"
           className={this.state.ptc == true ? "sc-688eee80-0 gzBlUv load" : "sc-688eee80-0 gzBlUv"}
           disabled={this.state.ptc == true ? true : false}
           onClick={()=>{
            this.setState({ptc:true})
            fetch(`${apiUrl}/raffles/${this.state.data.id}/cart/`, {
               method: 'POST',
               credentials: 'include',
               headers: {
                 'Content-Type': 'application/json',
               },
               body: JSON.stringify({
                numbers:this.state.selectedButtons.map(item => item + 1)
               })
             })
               .then(response => {
                 if (response.status === 403) {
                response.json().then(data=>{
                  
                  toast.error(data.errors[0].message, {
                     position: "top-center",
                     autoClose: 5000, // Fecha automaticamente após 3 segundos
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                   });

                })
                 }
                 if (response.status === 200) {
                   // Tratar resposta 200
                   return response.json();
                 }
               })
               .then(data => {
                  this.setState({ptc:false})
                if(data.status == 1){
                this.props.navigate(`/${data.slug}/checkout`)
                }
                 
               })
               .catch(error => {
                 // Lógica para lidar com o erro
                 console.error('Erro:', error);
               });
         }}>
           Reservar
         </button>
       </div>
     </div>
   </div>
 </div>
</>}

</> : <>


<div class="sc-cc128809-2 hjOGch">
<div class="sc-84f71e53-7 jXuczH">
<div class="sc-84f71e53-8 bSdbbZ">
   <p class="sc-84f71e53-2 eabebY">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-list ">
         <line x1="8" x2="21" y1="6" y2="6"></line>
         <line x1="8" x2="21" y1="12" y2="12"></line>
         <line x1="8" x2="21" y1="18" y2="18"></line>
         <line x1="3" x2="3.01" y1="6" y2="6"></line>
         <line x1="3" x2="3.01" y1="12" y2="12"></line>
         <line x1="3" x2="3.01" y1="18" y2="18"></line>
      </svg>
      Selecione a quantidade de bilhetes
   </p>
   <div class="sc-84f71e53-9 fPyHdM">
   <button type="button" aria-label="Selecionar mais 20 bilhete(s)" class="sc-84f71e53-10 cAljXc" onClick={()=>{

     
var n = (this.state.manualSlc + 20)

if(n <= this.state.data.numbers){
this.setState({manualSlc:n})
}else{
this.setState({manualSlc:this.state.data.numbers})
}
 

}}><span>Selecionar</span><span class="sc-84f71e53-11 dPNhKc">+20</span></button>
   <button type="button" aria-label="Selecionar mais 40 bilhete(s)" class="sc-84f71e53-10 cAljXc most-popular"><span>Selecionar</span><span class="sc-84f71e53-11 dPNhKc" onClick={()=>{

     
var n = (this.state.manualSlc + 40)

if(n <= this.state.data.numbers){
this.setState({manualSlc:n})
}else{
this.setState({manualSlc:this.state.data.numbers})
}
 

}}>+40</span></button>
   <button type="button" aria-label="Selecionar mais 60 bilhete(s)" class="sc-84f71e53-10 cAljXc" onClick={()=>{

     
var n = (this.state.manualSlc + 60)

if(n <= this.state.data.numbers){
this.setState({manualSlc:n})
}else{
this.setState({manualSlc:this.state.data.numbers})
}
 

}}><span>Selecionar</span><span class="sc-84f71e53-11 dPNhKc">+60</span></button>
   <button type="button" aria-label="Selecionar mais 100 bilhete(s)" class="sc-84f71e53-10 cAljXc" onClick={()=>{

     
    var n = (this.state.manualSlc + 100)

if(n <= this.state.data.numbers){
   this.setState({manualSlc:n})
}else{
   this.setState({manualSlc:this.state.data.numbers})
}
      

   }}><span>Selecionar</span><span class="sc-84f71e53-11 dPNhKc">+100</span></button>
   </div>
   <div class="sc-84f71e53-12 hWmTaP">
      <div class="sc-84f71e53-13 kydLCH">
         <button type="button" aria-label="Desselecionar 1 bilhete" class="sc-84f71e53-14 caCTUG" onClick={()=>{
            if (this.state.manualSlc > 10) {
               this.setState(prevState => ({
                 manualSlc: prevState.manualSlc - 1
               }));
             }
         }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-minus ">
               <path d="M5 12h14"></path>
            </svg>
         </button>
         <input aria-label="Quantidade de bilhetes" readonly="" class="sc-84f71e53-15 fRjceb" value={this.state.manualSlc}/>
         <button type="button" aria-label="Selecionar mais 1 bilhete" class="sc-84f71e53-14 caCTUG" onClick={()=>{
            if (this.state.manualSlc < this.state.data.numbers) {
            this.setState(prevState => ({
               manualSlc: prevState.manualSlc + 1
             }));
            }
         }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus ">
               <path d="M5 12h14"></path>
               <path d="M12 5v14"></path>
            </svg>
         </button>
      </div>
   </div>
</div>
<hr/>
<div class="sc-84f71e53-8 bSdbbZ">
   <div class="sc-84f71e53-16 exzoGB"><button type="button" id="checkout-button" aria-label="Participar"  className={this.state.ptc == true ? "sc-84f71e53-17 khOvmf load" : "sc-84f71e53-17 khOvmf"}
           disabled={this.state.ptc == true ? true : false}
           onClick={()=>{
            this.setState({ptc:true})
            fetch(`${apiUrl}/raffles/${this.state.data.id}/cart/`, {
               method: 'POST',
               credentials: 'include',
               headers: {
                 'Content-Type': 'application/json',
               },
               body: JSON.stringify({
                numbers:this.state.manualSlc
               })
             })
               .then(response => {
                  if (response.status === 502) {
                     this.setState({ptc:false})
                  }
                 if (response.status === 403) {
                response.json().then(data=>{
                  
                  toast.error(data.errors[0].message, {
                     position: "top-center",
                     autoClose: 5000, // Fecha automaticamente após 3 segundos
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                   });

                })
                 }
                 if (response.status === 200) {
                   // Tratar resposta 200
                   return response.json();
                 }
               })
               .then(data => {
                  this.setState({ptc:false})
                if(data.status == 1){
                this.props.navigate(`/${data.slug}/checkout`)
                }
                 
               })
               .catch(error => {
                  this.setState({ptc:false})
                 console.error('Erro:', error);
               });
         }}>Reservar ({Number(this.state.manualSlc * this.state.data.price).toLocaleString('pt-BR', {
 style: 'currency',
 currency: 'BRL',
})})</button></div>
</div>
</div>


</div>

</>}
</div>


</main>
 
 </>}

<div id="modal-root"></div>
{this.state.buscN && <>
   <div id="modal-root">
  
      <div class="sc-912e3955-1 guWPuq" onClick={()=>{
         this.setState({buscN:undefined,resbuy:undefined})
      }}></div>
 
 
         <div class="sc-912e3955-0 fyGHVY">
            <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" class="sc-b992e925-1 kVYZTt">
               <div class="sc-b992e925-4 eCuIZO">
                  <h1 class="sc-b992e925-2 iEZsiF">Ver meus bilhetes</h1>
                  <button type="button" class="sc-b992e925-3 hoeLrA" onClick={()=>{
         this.setState({buscN:undefined})
      }}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x ">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                     </svg>
                  </button>
               </div>
               {this.state.resbuy != undefined ? <>

                  <div class="sc-5d870d23-3 eEdlOu">
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-info">
      <circle cx="12" cy="12" r="10"></circle>
      <path d="M12 16v-4"></path>
      <path d="M12 8h.01"></path>
   </svg>
   <span>Clique em um pedido pendente para pagá-lo.</span>
</div>
<div class="infinite-scroll-component__outerdiv">
  
<div class="infinite-scroll-component scroll-area" style={{height:'auto',overflow:'auto'}}>
{this.state.resbuy.map((item)=><>
   {item.status == "in_process" ? <>
   
      <Link to={`${window.location.href}/checkout/${item.id}`} target="_blank" rel="noopener noreferrer" class="sc-5d870d23-2 hHGJyv pending" aria-label="Ir para o checkout do pedido">
         <div class="sc-5d870d23-5 ernSzK">
            <div class="sc-5d870d23-6 ifIXzx">
               <div>
                  <div class="sc-5d870d23-4 dhdqBw pending">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clock">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 6 12 12 16 14"></polyline>
                     </svg>
                  </div>
               </div>
               <h4>Pedido pendente</h4>
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link">
                  <path d="M15 3h6v6"></path>
                  <path d="M10 14 21 3"></path>
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
               </svg>
            </div>
            <div class="sc-5d870d23-7 cGjggB">
            {item.numbers != "" ? <>
               {item.numbers.split(",").map((item)=><>
               <div class="sc-5d870d23-8 boKkMv">{item}</div>
               </>)}
               </> : <>
               Números disponivél após a compra
               </>}
            </div>
            <p>{new Number(item.transaction_amount).toLocaleString('pt-BR', {
       style: 'currency',
       currency: 'BRL',
     })} · há {item.created_at}</p>
         </div>
      </Link>
   </> : <>
      <div  target="_blank" rel="noopener noreferrer" class="sc-5d870d23-2 hHGJyv approved" aria-label="Ir para o checkout do pedido">
         <div class="sc-5d870d23-5 ernSzK">
            <div class="sc-5d870d23-6 ifIXzx">
               <div>
                  <div class="sc-5d870d23-4 dhdqBw pending">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clock">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 6 12 12 16 14"></polyline>
                     </svg>
                  </div>
               </div>
               <h4>Pedido aprovado</h4>
             
            </div>
            <div class="sc-5d870d23-7 cGjggB">
               {item.numbers != "" ? <>
               {item.numbers.split(",").map((item)=><>
               <div class="sc-5d870d23-8 boKkMv">{item}</div>
               </>)}
               </> : <>
               Números disponivél após a compra
               </>}
          
            </div>
            <p>{new Number(item.transaction_amount).toLocaleString('pt-BR', {
       style: 'currency',
       currency: 'BRL',
     })} · há {item.created_at}</p>
         </div>
      </div>
   
   </>}
   
</>)}
 </div> 
  
</div>

         
               </> : <>
               <div class="sc-b992e925-5 gmRYUp">
                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Telefone com DDD</span></div>
                     <div class="sc-56eec735-2 bBSNUk">
                     <InputMask mask={this.state.mask} class="sc-56eec735-0 bNrsML2" onChange={(e)=>{
                 var phone = e.target.value;
                 if (/^\d{2} 9\d/.test(phone)) {
                    this.setState({mask:'99 99999-9999'})
                  } else {
                    this.setState({mask:'99 9999-9999'})
                  }
                
                
                  this.setState({phone})

              }} value={this.state.phone}  placeholder="Telefone" />
                        </div>
                     <span class="sc-babfcf74-0 err-pg"></span>
                  </fieldset>
                  <div class="sc-8abcf529-1 kIrWpL">
                     <button type="submit" class="sc-688eee80-0 dSLnGJ" aria-label="Buscar" onClick={()=>{
                        fetch(`${apiUrl}/raffles/${this.state.data.id}/orders/search-by-phone/${this.state.phone.replace(/\D/g, '')}`, {
                           method: 'GET',
                           credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
                           headers: {
                             'Authorization': 'Bearer '+Cookies.get('auth_user'), // Se necessário, adicione um cabeçalho de autorização
                             'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
                           }
                           })
                           .then(response => {
                           if (response.status == 404) {
                           
                             toast.error("Não encontramos nem uma campanha para esse número!")
                           
                           }

                           if(response.status == 200){
                              response.json().then(data=>{
                                 this.setState({resbuy:data})
                              })
                           }

                        })
                     }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search ">
                           <circle cx="11" cy="11" r="8"></circle>
                           <path d="m21 21-4.3-4.3"></path>
                        </svg>
                        Buscar
                     </button>
                  </div>
               </div>
               </>}
             
            </div>
         </div>
      </div>
 

</>}
<ToastContainer
position="top-center"
autoClose={5000}

theme="light"

/>
 </>
    );
  }
}

export default Page404;