import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

class Section8 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isActive1: false,
          isActive2:false,
          isActive3:false,
          isActive4:false,
          isActive5:false,
        };
      }
      handleClick1 = () => {
    
        this.setState(prevState => ({
          isActive1: !prevState.isActive1
        }));
      };

      handleClick2 = () => {
       
        this.setState(prevState => ({
          isActive2: !prevState.isActive2
        }));
      };

      handleClick3 = () => {
        
        this.setState(prevState => ({
          isActive3: !prevState.isActive3
        }));
      };


      handleClick4 = () => {
        
        this.setState(prevState => ({
          isActive4: !prevState.isActive4
        }));
      };

      handleClick5 = () => {
        
        this.setState(prevState => ({
          isActive5: !prevState.isActive5
        }));
      };

  render() {
    const { isActive1,isActive2,isActive3,isActive4,isActive5 } = this.state;
    return (
 <>
 


   <div class="sc-2c403d08-8 klLsrI">
      <span id="faq" class="sc-2c403d08-31 edJEYY"></span>
      <h2 class="sc-2c403d08-17 jdVlnJ">Dúvidas frequentes</h2>
      <div class="sc-2c403d08-32 LQgxx">
         <div class="sc-2c403d08-33 drgfcZ">
            <div>
               <button className={`sc-2c403d08-34 kcRGTn ${isActive1 ? 'active' : ''}`} aria-expanded="false" onClick={this.handleClick1} aria-controls="collapsible-content-1">
                  <span>Quem recebe o valor dos bilhetes vendidos?</span>
                  <div class="chevron">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down ">
                        <path d="m6 9 6 6 6-6"></path>
                     </svg>
                  </div>
               </button>
               {this.state.isActive1 && <>
                <div id="collapsible-content-1" aria-hidden="true" class="rah-static rah-static--height-zero ">
                  <div>
                     <div class="sc-2c403d08-35 ecqnsx">
                        <p>O valor dos bilhetes vão diretamente para a sua conta bancária. No painel administrativo você adíciona as formas de pagamentos que deseja na sua campanha, como pix, cartão de crédito, etc.</p>
                     </div>
                  </div>
               </div>
               </>}
             
            </div>
            <div>
               <button className={`sc-2c403d08-34 kcRGTn ${isActive2 ? 'active' : ''}`} onClick={this.handleClick2} aria-expanded="false" aria-controls="collapsible-content-2">
                  <span>O sistema possui baixa automática dos bilhetes?</span>
                  <div class="chevron">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down ">
                        <path d="m6 9 6 6 6-6"></path>
                     </svg>
                  </div>
               </button>
               {isActive2 && <>
                <div id="collapsible-content-2" aria-hidden="true" class="rah-static rah-static--height-zero " >
                  <div>
                     <div class="sc-2c403d08-35 ecqnsx">
                        <p>Sim, caso você queira é possível integrar os pagamentos da sua campanha com o Mercado Pago, sendo assim, os bilhetes pagos irão ter o status alterado automaticamente.
                        
                           Mas caso seja do seu interesse ter formas de pagamento manuais, os clientes irão efetuar o pagamento na sua campanha, e você irá alterar os pedidos para pago no painel administrativo.
                        </p>
                     </div>
                  </div>
               </div>
               </>}
            
            </div>
            <Link to="/precos" class="sc-2c403d08-34 kcRGTn">
     
               <span>Qual o valor para criar uma campanha?</span>
               <div class="chevron">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link ">
                     <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                     <polyline points="15 3 21 3 21 9"></polyline>
                     <line x1="10" x2="21" y1="14" y2="3"></line>
                  </svg>
               </div>
            </Link>
         </div>
         <div class="sc-2c403d08-33 drgfcZ">
            <div>
               <button className={`sc-2c403d08-34 kcRGTn ${isActive3 ? 'active' : ''}`} onClick={this.handleClick3} aria-expanded="false" aria-controls="collapsible-content-4">
                  <span>O que acontece com os bilhetes não pagos?</span>
                  <div class="chevron">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down ">
                        <path d="m6 9 6 6 6-6"></path>
                     </svg>
                  </div>
               </button>
               {isActive3 && <>
                <div id="collapsible-content-4" aria-hidden="true" class="rah-static rah-static--height-zero " >
                  <div>
                     <div class="sc-2c403d08-35 ecqnsx">
                        <p>No sistema você irá definir um período entre 10 minutos e 60 dias para a reserva dos bilhetes expirarem, após esta quantidade de dias estabelecida, os bilhetes não pagos irão ficar disponíveis para compra novamente.</p>
                     </div>
                  </div>
               </div>
               </>}
              
            </div>
            <div>
               <button className={`sc-2c403d08-34 kcRGTn ${isActive4 ? 'active' : ''}`} onClick={this.handleClick4} aria-expanded="false" aria-controls="collapsible-content-5">
                  <span>Quantas campanhas eu posso fazer?</span>
                  <div class="chevron">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down ">
                        <path d="m6 9 6 6 6-6"></path>
                     </svg>
                  </div>
               </button>
               {isActive4 && <>
                <div id="collapsible-content-5" aria-hidden="true" class="rah-static rah-static--height-zero " >
                  <div>
                     <div class="sc-2c403d08-35 ecqnsx">
                        <p>Você não tem limite de campanhas, mas lembre-se que cada campanha criada é necessário pagar a taxa da plataforma.</p>
                     </div>
                  </div>
               </div>
               </>}
              
            </div>
            <div>
               <button className={`sc-2c403d08-34 kcRGTn ${isActive5 ? 'active' : ''}`} onClick={this.handleClick5} aria-expanded="false" aria-controls="collapsible-content-6">
                  <span>O EuRifei organiza campanhas online?</span>
                  <div class="chevron">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down ">
                        <path d="m6 9 6 6 6-6"></path>
                     </svg>
                  </div>
               </button>
               {isActive5 && <>
                <div id="collapsible-content-6" aria-hidden="true" class="rah-static rah-static--height-zero ">
                  <div>
                     <div class="sc-2c403d08-35 ecqnsx">
                        <p>Não. O EuRifei disponibiliza toda a plataforma para os administradores das campanhas realizarem suas campanhas. A plataforma não requer eventuais autorizações que o organizador entender necessárias para a realização do seu sorteio.</p>
                     </div>
                  </div>
               </div>
               </>}
              
            </div>
         </div>
      </div>
   </div>




 </>
    );
  }
}

export default Section8;