import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../CheckOut/style.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'


import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { apiUrl } from '../Config';
import logo from "../../../src/images/logo.png"
import InputMask from 'react-input-mask'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dropdown,Drop,Item } from '../../Comps/Dropdown';
class ChecOutR extends React.Component {
   constructor(props) {
      super(props);
     
      this.state = {
     Loader:true,
     data:[],
     selectedButtons: [],
     page:"a",
     phone:"",
     userData:[],
     tpDc:'CPF',
     PayMethod:undefined,
     activeItems: [],
      }  
   }
   handleClick = (item) => {
      let newActiveItems = [];
      if (item === 'a') {
        newActiveItems = ['a'];
      } else if (item === 'b') {
        newActiveItems = ['a', 'b'];
      } else if (item === 'c') {
        newActiveItems = ['a', 'b', 'c'];
      }
      this.setState({ activeItems: newActiveItems });
    };
 validateCnpj = (cnpj) => {
      // Remove caracteres não numéricos
      const cleanedCnpj = cnpj.replace(/\D/g, '');
  
      // Verifica se o CNPJ tem 14 dígitos
      if (cleanedCnpj.length !== 14) {
        return false;
      }
  
      // Verifica se todos os dígitos são iguais
      if (/^(\d)\1{13}$/.test(cleanedCnpj)) {
        return false;
      }
  
      // Calcula os dígitos verificadores
      let sum = 0;
      let position = 5;
      for (let i = 0; i < 12; i++) {
        sum += parseInt(cleanedCnpj.charAt(i)) * position;
        position--;
        if (position < 2) {
          position = 9;
        }
      }
      let remainder = sum % 11;
      if (remainder < 2) {
        remainder = 0;
      } else {
        remainder = 11 - remainder;
      }
      if (remainder !== parseInt(cleanedCnpj.charAt(12))) {
        return false;
      }
  
      sum = 0;
      position = 6;
      for (let i = 0; i < 13; i++) {
        sum += parseInt(cleanedCnpj.charAt(i)) * position;
        position--;
        if (position < 2) {
          position = 9;
        }
      }
      remainder = sum % 11;
      if (remainder < 2) {
        remainder = 0;
      } else {
        remainder = 11 - remainder;
      }
      if (remainder !== parseInt(cleanedCnpj.charAt(13))) {
        return false;
      }
  
      return true;
    };
 validateCpf = (cpf) => {
      // Remove caracteres não numéricos
      const cleanedCpf = cpf.replace(/\D/g, '');
  
      // Verifica se o CPF tem 11 dígitos
      if (cleanedCpf.length !== 11) {
        return false;
      }
  
      // Verifica se todos os dígitos são iguais
      if (/^(\d)\1{10}$/.test(cleanedCpf)) {
        return false;
      }
  
      // Calcula os dígitos verificadores
      let sum = 0;
      for (let i = 1; i <= 9; i++) {
        sum += parseInt(cleanedCpf[i - 1]) * (11 - i);
      }
      let remainder = (sum * 10) % 11;
      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }
      if (remainder !== parseInt(cleanedCpf[9])) {
        return false;
      }
  
      sum = 0;
      for (let i = 1; i <= 10; i++) {
        sum += parseInt(cleanedCpf[i - 1]) * (12 - i);
      }
      remainder = (sum * 10) % 11;
      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }
      if (remainder !== parseInt(cleanedCpf[10])) {
        return false;
      }
  
      return true;
    };

componentDidMount(){
   this.handleClick('a')
   document.body.style.backgroundColor = '#f8f8fa';
   var url = window.location.href;
   let regex = /\/([^/]+)\/checkout/;
   // Executar a expressão regular na URL para obter o ID
   let match = url.match(regex);
   fetch(`${apiUrl}/rifinha/${match[1]}/cart/`, {
      method: 'GET',
      credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
      headers: {
        'Authorization': 'Bearer '+Cookies.get('auth_user'), // Se necessário, adicione um cabeçalho de autorização
        'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
      }
    })
    .then(response => {
      if (response.status == 402) {
    
         window.history.back();
   
      }
    
  
if(response.status == 200){
   response.json().then(data=>{
      document.title = "CheckOut | EuRifei"
      this.setState({data,Loader:false,manual:false}) 
   })
}



   
    })
}

Show = (t) =>{
   switch(t){
      case 'a':
      return(<>
       <div class="sc-538d1dac-3 hsrHLW">
  




  <div class="sc-538d1dac-5 iizWQl">
    
     <div class="sc-538d1dac-8 jpPaPV">
        <div class="sc-538d1dac-6 hLDlwv">
           <div class="sc-538d1dac-7 cuMLcU">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                 <circle cx="12" cy="8" r="5"></circle>
                 <path d="M20 21a8 8 0 1 0-16 0"></path>
              </svg>
           </div>
           <h2>Dados pessoais</h2>
        </div>
        <fieldset class="sc-8694035b-0 dyeolP">
           <div class="sc-501655dd-0 jbvgZV"><span>Telefone com DDD</span></div>
           <div class="sc-8694035b-3 jLDXSB">
              
              <div class="sc-8694035b-4 jwGRQT">

         
              <InputMask mask={this.state.mask} class={this.state.checkPhone == undefined ? "sc-8694035b-5 keYBYa" : 
              this.state.checkPhone == true ? "sc-8694035b-5 bNrsML" : "sc-8694035b-5 dgQesr"} onChange={(e)=>{
                 var phone = e.target.value;
                 if (/^\d{2} 9\d/.test(phone)) {
                    this.setState({mask:'99 99999-9999'})
                  } else {
                    this.setState({mask:'99 9999-9999'})
                  }
                
                  const regex = /^(\d{2} )?(\d{4,5}-\d{4})$/;
                  const valido = regex.test(phone);
                  this.setState({checkPhone:valido,phone})

              }}  placeholder="Telefone" />
              </div>
           </div>
        </fieldset>
     </div>

  </div>
  <div class="sc-538d1dac-11 emCdKh">
  <button type="submit" class="sc-688eee80-0 YPgQa" aria-label="Continuar"
  onClick={()=>{



   
if(this.state.checkPhone == false || this.state.checkPhone == undefined){
   toast.error('Número de telefone inválido!', {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   this.setState({checkPhone:false})
   return false;
}



     var phone = (this.state.phone.replace(/\D/g, ''))

     fetch(`${apiUrl}/buyers/${phone}`, {
        method: 'GET',
        credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
        headers: {
          'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
        }
      })
      .then(response => {
        if (response.status == 404) {
           
           this.setState({page:"e",NotNumber:this.state.phone,svu:true})
     
        }
      
       




        if (response.status == 200) {
        response.json().then(userData=>{
           this.setState({userData,page:"c"})
        })
          
     
        }
        
     
      }).then(result => {
 
       
      
        })
        .catch(error => {
      console.log(error)   
      });



  }}
  >Continuar</button>
  </div>

</div>
      </>)
      break;
      case 'c':
      return(<>
      <div class="sc-538d1dac-3 hsrHLW">
      <div class="sc-538d1dac-5 iizWQl">
         <div class="sc-538d1dac-8 jpPaPV">
            <div class="sc-538d1dac-6 hLDlwv">
               <div class="sc-538d1dac-7 cuMLcU">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-in ">
                     <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                     <polyline points="10 17 15 12 10 7"></polyline>
                     <line x1="15" x2="3" y1="12" y2="12"></line>
                  </svg>
               </div>
               <h2>Deseja continuar como:</h2>
            </div>
           
            <div class="sc-bd245ce6-1 gZlGrX">
               <div class="sc-bd245ce6-2 iIbpzn"><span><b>Nome:</b></span><span>{this.state.userData.nome}</span></div>
               <div class="sc-bd245ce6-2 iIbpzn"><span><b>Telefone:</b></span><span>{this.state.userData.telefone}</span></div>
               <div class="sc-bd245ce6-2 iIbpzn"><span><b>E-mail:</b></span><span>{this.state.userData.email}</span></div>
               <div class="sc-bd245ce6-2 iIbpzn"><span><b>CPF/CNPJ:</b></span><span>{this.state.userData.documento}</span></div>
            </div>
         </div>
      </div>
      <div class="sc-538d1dac-11 emCdKh">
         <button type="button" aria-label="Alterar dados" class="sc-688eee80-5 uobyt" onClick={()=>{
              this.handleClick("a")
            this.setState({page:"e"})
         }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-refresh-ccw ">
               <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"></path>
               <path d="M3 3v5h5"></path>
               <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16"></path>
               <path d="M16 16h5v5"></path>
            </svg>
            Alterar dados
         </button>
         <button type="button" aria-label="Continuar" class="sc-688eee80-0 YPgQa" onClick={()=>{
           this.handleClick("b")
           this.setState({page:"f"})
         }}>Continuar</button>
      </div>
   </div>

      </>)
      break;
      case 'e':
      return(<>
      <div class="sc-538d1dac-3 hsrHLW">
   
      <div class="sc-538d1dac-5 iizWQl">
         <div class="sc-538d1dac-8 jpPaPV">
            <div class="sc-538d1dac-6 hLDlwv">
               <div class="sc-538d1dac-7 cuMLcU">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                     <circle cx="12" cy="8" r="5"></circle>
                     <path d="M20 21a8 8 0 1 0-16 0"></path>
                  </svg>
               </div>
               <h2>Dados pessoais</h2>
            </div>
            <fieldset class="sc-948733ac-1 dFPHWQ">
               <div class="sc-501655dd-0 jbvgZV"><span>Nome completo</span></div>
               <div className={this.state.checkName == undefined ? "sc-948733ac-2 hRcnDV" : this.state.checkName == "" ? "sc-948733ac-2 boIzxF" : "sc-948733ac-2 dSakst"}>
               <input name="name" placeholder="Digite seu nome e sobrenome" class="sc-948733ac-0 guVjUX" onChange={(e)=>{
                var nome  = e.target.value;
         
this.setState({nomeDc:nome})
                if (!/^[a-zA-Z\s]+$/.test(nome)) {
                  this.setState({checkName:"Insira seu nome completo utilizando somente letras e espaços."})
                return false;
               }

               const parts = nome.split(' ');
               if((parts.length >= 2 && parts.every(part => part.trim() !== '')) == false){
                  this.setState({checkName:"Insira seu nome completo utilizando somente letras e espaços."})
               }else{
             
                  this.setState({checkName:""})
               }
               }} onBlur={(e)=>{
              if(this.state.checkName == undefined){
              this.setState({checkName:"Este é um campo obrigatório!"})
              }
               }} value={this.state.nome}/>
               </div>
               {this.state.checkName != undefined && <>
                  <span class="sc-b0bec892-0 jaqEvn">{this.state.checkName}</span>
               </>}
            </fieldset>
            <div class="sc-538d1dac-16 dxQxfd">
               <fieldset class="sc-8694035b-0 cJEmdv">
                  <div class="sc-501655dd-0 jbvgZV"><span>Telefone com DDD</span></div>
                  <div class="sc-8694035b-3 jLDXSB">
                    
                     <div class="sc-8694035b-4 jwGRQT">
                        

                    

               <InputMask mask={this.state.mask} class={this.state.checkPhone == undefined ? "sc-8694035b-5 keYBYa" : 
              this.state.checkPhone == true ? "sc-8694035b-5 bNrsML" : "sc-8694035b-5 dgQesr"} onChange={(e)=>{
                 var phone = e.target.value;
                 this.setState({phoneDc:phone})
               if(this.state.cfPhone != undefined){
var p1 =    (this.state.cfPhone.replace(/\D/g, ''))
var p2 =    (phone.replace(/\D/g, ''))
if(p1 == p2){
   this.setState({checkCt:true,erroCf:""})
   }else{
      this.setState({checkCt:false})
   }
               }
                 
                 
                 if (/^\d{2} 9\d/.test(phone)) {
                    this.setState({mask:'99 99999-9999'})
                  } else {
                    this.setState({mask:'99 9999-9999'})
                  }
                
                  const regex = /^(\d{2} )?(\d{4,5}-\d{4})$/;
                  const valido = regex.test(phone);
                  if(valido){
                  this.setState({ckNumber:''})
                  }else{
                  this.setState({ckNumber:'Número de telefone inválido!'})
                  }
                  this.setState({checkPhone:valido,NumberCad:phone})

              }} onBlur={()=>{
           
              }} disabled={this.state.NotNumber != undefined ? true : false} value={this.state.NotNumber != undefined ? this.state.NotNumber : this.state.NumberCad }  placeholder="Telefone" />
                    
                     </div>
                  </div>
                  {this.state.ckNumber!= "" && <>
                  <span class="sc-b0bec892-0 jaqEvn">{this.state.ckNumber}</span>
                  </>}
                




               </fieldset>
               <fieldset class="sc-8a01dbf2-0 uwEha">
                  <div class="sc-501655dd-0 jbvgZV"><span>Confirmar telefone</span></div>
                  <div class="sc-8a01dbf2-1 lhjBly">
                     <div class="sc-8a01dbf2-2 gBSXKE">
                     <InputMask mask={this.state.mask2}  name="rephone" onChange={(e)=>{
                       var phone = e.target.value;
                       this.setState({cfPhone:phone})
                       if (/^\d{2} 9\d/.test(phone)) {
                          this.setState({mask2:'99 99999-9999'})
                        } else {
                          this.setState({mask2:'99 9999-9999'})
                        }
                       
if(this.state.NotNumber == undefined){
if(this.state.NumberCad == undefined){
   this.setState({ckNumber:'Número de telefone inválido!',checkPhone:false,checkCt:false,erroCf:"Os telefones não coincidem!"})
}else{
var p1 =    (this.state.NumberCad.replace(/\D/g, ''))
var p2 =    (phone.replace(/\D/g, ''))
if(p1 == p2){
this.setState({checkCt:true,erroCf:""})
}else{
   this.setState({checkCt:false,erroCf:"Os telefones não coincidem!"})
}
}
                       }else{
                    
                        var p1 =    (this.state.NotNumber.replace(/\D/g, ''))
                        var p2 =    (phone.replace(/\D/g, ''))
                        if(p1 == p2){
                        this.setState({checkCt:true,erroCf:""})
                        }else{
                        this.setState({checkCt:false,erroCf:"Os telefones não coincidem!"})
                        }
                       }
                     }} type="tel" class={this.state.checkCt == undefined ? "sc-8694035b-5 keYBYa" : 
                     this.state.checkCt== true ? "sc-8694035b-5 bNrsML" : "sc-8694035b-5 dgQesr"} /></div>
                  </div>
                  {this.state.erroCf != "" && <>
                  <span class="sc-8a01dbf2-4 dgSsdE">{this.state.erroCf}</span>
                  </>}
               </fieldset>
            </div>
            <fieldset class="sc-948733ac-1 dFPHWQ">
               <div class="sc-501655dd-0 jbvgZV"><span>Endereço de e-mail</span></div>
               <div class="sc-948733ac-2 hRcnDV">
         <input name="email" placeholder="Digite seu e-mail" type="email" class={this.state.checkEM == undefined ? "sc-8694035b-5 keYBYa" : 
                     this.state.checkEM == true ? "sc-8694035b-5 bNrsML" : "sc-8694035b-5 dgQesr"} onChange={(e)=>{
                        var value = e.target.value;
                        this.setState({emailCt:value})
                        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      
                        if(regex.test(value)){
                           this.setState({checkEM:true,erroEm:""})
                        }else{
                        this.setState({checkEM:false,erroEm:"Insira um endereço de e-mail válido!"})
                        }
                     }} onBlur={()=>{
                        if(this.state.emailCt == undefined){
                           this.setState({checkEM:false,erroEm:"Este é um campo obrigatório!"})
                        }
                     }} value={this.state.emailCt}/></div>

{this.state.erroEm != "" && <>
                  <span class="sc-8a01dbf2-4 dgSsdE">{this.state.erroEm}</span>
                  </>}
            </fieldset>
            <fieldset class="sc-ee5fde5-1 jpUoUO">
               <div class="sc-501655dd-0 jbvgZV"><span>CPF/CNPJ</span></div>
               <div class={this.state.checkDc == undefined ? "sc-ee5fde5-3 gLccNz" : 
                     this.state.checkDc == true ? "sc-ee5fde5-3 fqeqLW" : "sc-ee5fde5-3 fCsrWT"}>
                  <div class="sc-b1f30d56-2 htQhAv dropdown-wrapper">
                 
                 
                   <Dropdown>
                     <Drop>
                     <button type="button" class="sc-b1f30d56-0 ktKnRJ">
                        <span>{this.state.tpDc}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down ">
                           <path d="m6 9 6 6 6-6"></path>
                        </svg>
                     </button>
                     </Drop>
                     <Item>
      <div role="menu" className="sc-caac7e0-0 gKOdsN" style={{ opacity: 1, transform: 'none' }}>
      <div className="sc-b1f30d56-1 eAUIFY"></div>
      <button type="button" CloseMe="true" onClick={()=>{
         this.setState({tpDc:'CPF',dcDoc:"",erroDc:"",checkDc:undefined})
      }}><span CloseMe="true">CPF</span></button>
      <button type="button" CloseMe="true"  onClick={()=>{
         this.setState({tpDc:'CNPJ',dcDoc:"",erroDc:"",checkDc:undefined})
      }}><span CloseMe="true">CNPJ</span></button>
    </div>
                     </Item>
                   </Dropdown>
                    


                  </div>

<InputMask class="sc-ee5fde5-0 KQRKs"  mask={this.state.tpDc == "CPF" ? "999.999.999-99" : " 99.999.999/9999-99"} onChange={(e)=>{
  this.setState({dcDoc:e.target.value})
  if(this.state.tpDc == "CPF"){
    if(this.validateCpf(e.target.value) == false){
   this.setState({erroDc:"Documento inválido!",checkDc:false})
    }else{
      this.setState({erroDc:"",checkDc:true})
    }
   }else{
      if(this.validateCnpj(e.target.value) == false){
         this.setState({erroDc:"Documento inválido!",checkDc:false})
          }else{
            this.setState({erroDc:"",checkDc:true})
          }
   }
}} value={this.state.dcDoc} autoComplete='off'/>
               </div>
               {this.state.erroDc != "" && <>
                  <span class="sc-8a01dbf2-4 dgSsdE">{this.state.erroDc}</span>
                  </>}
            </fieldset>
         
            <div class="sc-e3640b32-0 cXNozd"></div>
   
            <label>
   <input type="checkbox" name="save_data" class="sc-de2a189-2 dSLVMz" checked={this.state.svu} onClick={()=>{
      this.setState({svu:!this.state.svu})
   }}/>
   <div class="sc-de2a189-1 iVvtxZ">
      <div size="20" class="sc-de2a189-0 kAhrXF">
         <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="10px" view-box="12px 10px">
            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
         </svg>
      </div>
      <span>Salvar dados para próximas compras</span>
   </div>
</label>


         </div>
      </div>
      <div class="sc-538d1dac-11 emCdKh src"><button type="submit" class="sc-688eee80-0 YPgQa" aria-label="Continuar" onClick={()=>{
         if(this.state.checkName == undefined || this.state.checkName != ""){
            this.setState({checkName:"Este é um campo obrigatório!"})
            return false;
         }

         if(this.state.checkPhone == undefined || this.state.checkPhone != true){
            this.setState({checkPhone:false})
            return false;
         }

         if(this.state.checkCt== undefined || this.state.checkCt != true){
            this.setState({checkCt:false})
            return false;
         }

         if(this.state.checkEM== undefined || this.state.erroEm != ""){
            this.setState({checkEM:false,erroEm:"Insira um endereço de e-mail válido!"})
            return false;
         }
        
        
         if(this.state.checkDc == undefined){
            this.setState({erroDc:"Documento inválido!"})
            return false
         }

if(this.state.tpDc == "CPF"){
  if(this.validateCpf(this.state.dcDoc) == false){
   this.setState({erroDc:"Documento inválido!"})
   return false;
  }
}

if(this.state.tpDc == "CNPJ"){
   if(this.validateCnpj(this.state.dcDoc) == false){
    this.setState({erroDc:"Documento inválido!"})
    return false;
   }
 }
 
 this.setState({userData:{
   nome:this.state.nomeDc,
   telefone:this.state.phoneDc,
   email:this.state.emailCt,
   documento:this.state.dcDoc
 },
 
page:"f",manual:true})
this.handleClick("b")
      
      }}>Continuar</button></div>
  
</div>

      </>)
      break;
      case 'f':
      return(<>
      <div class="sc-538d1dac-3 hsrHLW">
      <div class="sc-538d1dac-5 iizWQl">
   <div class="sc-538d1dac-8 jpPaPV">
      <div class="sc-538d1dac-6 hLDlwv">
         <div class="sc-538d1dac-7 cuMLcU">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" stroke="currentColor" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <rect width="20" height="12" x="2" y="6" rx="2"></rect>
               <circle cx="12" cy="12" r="2"></circle>
               <path d="M6 12h.01M18 12h.01"></path>
            </svg>
         </div>
         <h2>Forma de pagamento</h2>
      </div>
      {this.state.data.bank.length == 0 ? <>
  <div class="no-pgmt">Não há formas de pagamento ativas para esta campanha no momento.</div>
     </> : <>
     {this.state.data.bank.AllowPix == 1 && <>
      <button type="button" aria-label="Selecionar Pix como forma de pagamento" class="sc-f361d3b-0 ljKbxV" onClick={()=>{
         this.handleClick("c")
         this.setState({
            page:'g',
            PayMethod:'pix'
         })
      }}>
         <div class="sc-f361d3b-1 eJvews icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="24" height="24" fill="currentColor">
               <path d="m18.5 7.2c1.5 1.5 1.5 4.1 0 5.6l-5.7 5.7c-1.5 1.5-4.1 1.5-5.6 0l-5.7-5.7c-1.5-1.5-1.5-4.1 0-5.6l5.7-5.7c1.5-1.5 4.1-1.5 5.6 0zm-1.4 1.4l-5.6-5.6c-0.8-0.8-2.1-0.8-2.9 0l-5.6 5.6c-0.8 0.8-0.8 2.1 0 2.9l5.6 5.6c0.8 0.8 2.1 0.8 2.9 0l5.6-5.6c0.8-0.8 0.8-2.1 0-2.9z"></path>
               <path d="m16.4 7.9c-1-0.9-2.4-1.1-3.5-0.5l-2.9 1.8-2.9-1.8c-1.1-0.6-2.5-0.4-3.5 0.5l-1.4-1.4c1.6-1.6 4-1.9 5.9-0.8l1.9 1.1 1.9-1.1c1.9-1.1 4.3-0.8 5.9 0.8z"></path>
               <path d="m3.6 12.1c1 0.9 2.4 1.1 3.5 0.5l2.9-1.8 2.9 1.8c1.1 0.6 2.5 0.4 3.5-0.5l1.4 1.4c-1.6 1.6-4 1.9-5.9 0.8l-1.9-1.1-1.9 1.1c-1.9 1.1-4.3 0.8-5.9-0.8z"></path>
            </svg>
         </div>
         <div class="sc-f361d3b-2 gQKNjx">
            <h3>Pix</h3>
            <span>Aprovação imediata</span>
         </div>
         <div class="sc-f361d3b-3 bbWUZr">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
               <path d="m9 18 6-6-6-6"></path>
            </svg>
         </div>
   </button>
     </>}
    
     {this.state.data.bank.AllowCro == 1 && <>
      <button type="button" aria-label="Selecionar cartão de crédito como forma de pagamento" class="sc-f361d3b-0 ljKbxV" onClick={()=>{
           this.handleClick("c")
         this.setState({
            page:'g',
            PayMethod:'credit_card'
         })
      }}>
         <div class="sc-f361d3b-1 eJvews icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-credit-card ">
               <rect width="20" height="14" x="2" y="5" rx="2"></rect>
               <line x1="2" x2="22" y1="10" y2="10"></line>
            </svg>
         </div>
         <div class="sc-f361d3b-2 gQKNjx">
            <h3>Cartão de crédito</h3>
         </div>
         <div class="sc-f361d3b-3 bbWUZr">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
               <path d="m9 18 6-6-6-6"></path>
            </svg>
         </div>
      </button>
     </> }
   
     
     
     </>}
   </div>
</div>
</div>
      </>)
      break;
      case 'g':

   return(<>
   
   <div class="sc-538d1dac-3 hsrHLW">
   <div class="sc-538d1dac-5 iizWQl">
      <div class="sc-538d1dac-8 jpPaPV">
         <div class="sc-538d1dac-6 hLDlwv">
            <div class="sc-538d1dac-7 cuMLcU">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text ">
                  <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" x2="8" y1="13" y2="13"></line>
                  <line x1="16" x2="8" y1="17" y2="17"></line>
                  <line x1="10" x2="8" y1="9" y2="9"></line>
               </svg>
            </div>
            <h2>Revisar</h2>
         </div>
         <div class="sc-3c689af2-0 idIePo">
            <span class="sc-3c689af2-1 hAOZWw">Dados pessoais</span>
            <button type="button" aria-label="Editar" class="sc-3c689af2-2 hRjzqT" onClick={()=>{
                this.handleClick("a")
              this.setState({page:'c'})
            }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pen ">
                  <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
               </svg>
               <span>Editar</span>
            </button>
         </div>
         <div class="sc-3c689af2-3 cYRnfj">
            <div class="sc-3c689af2-4 hIJcsG">
               <p>Nome:</p>
               <span>{this.state.userData.nome}</span>
               <p>Telefone:</p>
               <span>{this.state.manual == false ? this.state.userData.telefone : this.state.cfPhone.replace(/\D/g, '')}</span>
               <p>E-mail:</p>
               <span>{this.state.userData.email}</span>
               <p>CPF/CNPJ:</p>
               <span>{this.state.userData.documento}</span>
            </div>
         </div>
         {this.state.PayMethod == "credit_card" && <>
         <div class="sc-3c689af2-5 dPgPdn">
         <span>Os dados pessoais devem ser o mesmo do titular do cartão.</span>
         </div>
         </>}
         <div class="sc-3c689af2-0 idIePo">
         <span class="sc-3c689af2-1 hAOZWw">Forma de pagamento</span>
         <button type="button" aria-label="Alterar" class="sc-3c689af2-2 hRjzqT" onClick={()=>{
            this.handleClick("b")
           this.setState({page:"f",PayMethod:undefined})
         }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pen "><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path></svg><span>Alterar</span></button>
       
         </div>
          <div class="sc-3c689af2-3 cYRnfj no-mb">
            {this.state.PayMethod == "pix"&&  <>
            <div class="sc-3c689af2-6 heCbWA">
               <div class="sc-3c689af2-7 bPCeqz">

                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#32bcad" viewBox="0 0 24 24">
                     <path d="M18.69 18.34C18.46 18.34 18.23 18.32 18.01 18.28C17.78 18.23 17.56 18.17 17.35 18.08C17.13 17.99 16.93 17.88 16.74 17.75C16.55 17.63 16.37 17.48 16.2 17.32L12.61 13.72C12.54 13.66 12.47 13.61 12.39 13.58C12.31 13.55 12.22 13.53 12.14 13.53C12.05 13.53 11.96 13.55 11.88 13.58C11.8 13.61 11.73 13.66 11.66 13.72L8.05 17.33C7.89 17.49 7.71 17.64 7.52 17.77C7.33 17.89 7.13 18 6.91 18.09C6.7 18.18 6.48 18.25 6.25 18.29C6.03 18.34 5.8 18.36 5.56 18.36L4.86 18.36L9.42 22.91C9.76 23.25 10.16 23.52 10.6 23.7C11.04 23.88 11.51 23.98 11.99 23.98C12.47 23.98 12.94 23.88 13.39 23.7C13.83 23.52 14.23 23.25 14.57 22.91L19.13 18.35L18.69 18.34Z"></path>
                     <path d="M5.57 5.63C5.8 5.63 6.03 5.65 6.25 5.69C6.48 5.74 6.7 5.8 6.91 5.89C7.13 5.98 7.33 6.09 7.52 6.22C7.71 6.35 7.89 6.49 8.06 6.66L11.66 10.27C11.73 10.33 11.8 10.38 11.88 10.41C11.96 10.44 12.05 10.46 12.14 10.46C12.22 10.46 12.31 10.44 12.39 10.41C12.47 10.38 12.55 10.33 12.61 10.27L16.2 6.67C16.37 6.51 16.54 6.36 16.74 6.23C16.93 6.1 17.13 5.99 17.35 5.91C17.56 5.82 17.78 5.75 18.01 5.71C18.23 5.66 18.46 5.64 18.69 5.64L19.13 5.64L14.56 1.07C14.22 0.74 13.82 0.47 13.38 0.28C12.94 0.1 12.46 0.01 11.99 0.01C11.51 0.01 11.03 0.1 10.59 0.28C10.15 0.47 9.75 0.74 9.41 1.07L4.86 5.63L5.57 5.63Z"></path>
                     <path d="M22.9 9.42L20.14 6.66C20.13 6.66 20.11 6.67 20.1 6.68C20.08 6.68 20.06 6.68 20.05 6.69C20.03 6.69 20.01 6.69 20 6.7C19.98 6.7 19.96 6.7 19.95 6.7L18.69 6.7C18.53 6.7 18.37 6.71 18.21 6.75C18.06 6.78 17.9 6.82 17.75 6.89C17.6 6.95 17.46 7.02 17.33 7.11C17.19 7.2 17.07 7.31 16.95 7.42L13.36 11.01C13.2 11.17 13.01 11.3 12.8 11.39C12.59 11.47 12.36 11.52 12.14 11.52C11.91 11.52 11.69 11.47 11.48 11.39C11.27 11.3 11.08 11.17 10.92 11.01L7.31 7.41C7.19 7.29 7.07 7.19 6.93 7.1C6.8 7.01 6.66 6.93 6.51 6.87C6.36 6.81 6.2 6.76 6.05 6.73C5.89 6.7 5.73 6.68 5.57 6.68L4.03 6.68C4.01 6.68 3.99 6.68 3.98 6.68C3.96 6.68 3.95 6.68 3.93 6.67C3.92 6.67 3.9 6.67 3.88 6.66C3.87 6.66 3.85 6.65 3.84 6.65L1.06 9.42C0.73 9.76 0.46 10.16 0.28 10.6C0.09 11.04 0 11.51 0 11.99C0 12.47 0.09 12.94 0.28 13.39C0.46 13.83 0.73 14.23 1.06 14.57L3.83 17.34C3.85 17.33 3.86 17.33 3.88 17.32C3.89 17.32 3.91 17.31 3.92 17.31C3.94 17.31 3.96 17.31 3.97 17.3C3.99 17.3 4 17.3 4.02 17.3L5.57 17.3C5.73 17.3 5.89 17.28 6.05 17.25C6.2 17.22 6.36 17.17 6.51 17.11C6.66 17.05 6.8 16.97 6.93 16.88C7.07 16.8 7.19 16.69 7.31 16.58L10.92 12.97C11.57 12.32 12.7 12.32 13.36 12.97L16.95 16.57C17.07 16.68 17.19 16.78 17.33 16.87C17.46 16.96 17.6 17.04 17.75 17.1C17.9 17.16 18.06 17.21 18.21 17.24C18.37 17.27 18.53 17.29 18.69 17.29L19.95 17.29C19.96 17.29 19.98 17.29 20 17.29C20.01 17.29 20.03 17.29 20.05 17.3C20.06 17.3 20.08 17.3 20.1 17.31C20.11 17.31 20.13 17.32 20.14 17.33L22.9 14.57C23.24 14.23 23.51 13.83 23.69 13.39C23.88 12.94 23.97 12.47 23.97 11.99C23.97 11.51 23.88 11.04 23.69 10.6C23.51 10.16 23.24 9.76 22.9 9.42"></path>
                  </svg>

               </div>
               <span>Pix</span>
            </div>
            </>}
           {this.state.PayMethod == "credit_card" && <>
           <div class="sc-3c689af2-6 heCbWA"><div class="sc-3c689af2-7 bPCeqz"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3a6ac2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-credit-card "><rect width="20" height="14" x="2" y="5" rx="2"></rect><line x1="2" x2="22" y1="10" y2="10"></line></svg></div><span>Cartão de crédito</span></div>
           </>}
{this.state.PayMethod == "ted" && <>
<div class="sc-3c689af2-6 heCbWA">
   <div class="sc-3c689af2-7 bPCeqz">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <line x1="3" x2="21" y1="22" y2="22"></line>
         <line x1="6" x2="6" y1="18" y2="11"></line>
         <line x1="10" x2="10" y1="18" y2="11"></line>
         <line x1="14" x2="14" y1="18" y2="11"></line>
         <line x1="18" x2="18" y1="18" y2="11"></line>
         <polygon points="12 2 20 7 4 7"></polygon>
      </svg>
   </div>
   <span>Transferência bancária</span>
</div>

</>}
         </div>
      </div>
   </div>
{this.state.PayMethod == "credit_card" ? <>
<form action="#">
   <div class="sc-538d1dac-5 iizWQl bottom-card" style={{marginTop:'30px'}}>
      <div class="sc-538d1dac-8 jpPaPV">
         <div class="sc-538d1dac-6 hLDlwv">
            <div class="sc-538d1dac-7 cuMLcU">
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-credit-card ">
                  <rect width="20" height="14" x="2" y="5" rx="2"></rect>
                  <line x1="2" x2="22" y1="10" y2="10"></line>
               </svg>
            </div>
            <h2>Cartão de crédito</h2>
         </div>
         <fieldset class="sc-948733ac-1 dFPHWQ">
            <div class="sc-501655dd-0 jbvgZV">
               <span>Titular do cartão</span>
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-help-circle " data-tooltip-id="cardholderName-help-tt">
                  <circle cx="12" cy="12" r="10"></circle>
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                  <path d="M12 17h.01"></path>
               </svg>
            </div>
            <div class="sc-948733ac-2 hRcnDV"><input name="cardholderName" placeholder="Digite o titular" type="text" class="sc-948733ac-0 guVjUX" value=""/></div>
         </fieldset>
         <fieldset class="sc-c70ab19f-1 iyqiXV">
            <fieldset class="sc-948733ac-1 DiVx">
               <div class="sc-501655dd-0 jbvgZV"><span>Número do cartão</span></div>
               <div class="sc-948733ac-2 hRcnDV"><InputMask name="cardNumber" type="text" id="cardNumber" class="sc-948733ac-0 guVjUX" mask="**** **** **** ****"/></div>
            </fieldset>
            <div class="sc-c70ab19f-4 jhwzct"></div>
         </fieldset>
         <div class="sc-538d1dac-9 bYMBwr">
            <fieldset class="sc-948733ac-1 dFPHWQ">
               <div class="sc-501655dd-0 jbvgZV"><span>Data de validade</span></div>
               <div class="sc-948733ac-2 hRcnDV"><InputMask name="cardExpiration" type="text" class="sc-948733ac-0 guVjUX" mask="99/99"/></div>
            </fieldset>
            <fieldset class="sc-948733ac-1 dFPHWQ">
               <div class="sc-501655dd-0 jbvgZV">
                  <span>Código de segurança</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-help-circle " data-tooltip-id="securityCode-help-tt">
                     <circle cx="12" cy="12" r="10"></circle>
                     <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                     <path d="M12 17h.01"></path>
                  </svg>
               </div>
               <div class="sc-948733ac-2 hRcnDV"><input name="securityCode" placeholder="Digite o código" type="text" maxlength="4" class="sc-948733ac-0 guVjUX" value=""/></div>
            </fieldset>
         </div>
        
      </div>
   </div>
   <div class="sc-c70ab19f-5 kAHHRt">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M6 8.32a7.43 7.43 0 0 1 0 7.36"></path>
         <path d="M9.46 6.21a11.76 11.76 0 0 1 0 11.58"></path>
         <path d="M12.91 4.1a15.91 15.91 0 0 1 .01 15.8"></path>
         <path d="M16.37 2a20.16 20.16 0 0 1 0 20"></path>
      </svg>
      <span>Pagamento processado por Mercado Pago</span>
   </div>
   <div class="sc-538d1dac-11 bYfgDP">
      <div class="sc-538d1dac-10 lfBXpo"><span>Eu li e concordo com os&nbsp;<a href="https://eurifei.com.br/ajuda/outros/termos-de-uso" target="_blank" rel="noopener noreferrer">termos de uso</a>&nbsp;e a&nbsp;<a href="https://eurifei.com.br/ajuda/outros/politica-de-privacidade" target="_blank" rel="noopener noreferrer">política de privacidade</a>&nbsp;e estou ciente que o Rifei NÃO se responsabiliza pelo pedido e que o Rifei NÃO reterá nenhum dado digitado do cartão.</span></div>
      <button type="submit" class="sc-688eee80-0 eBXdvs" aria-label="Pagar" disabled="">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-lock ">
            <rect width="18" height="11" x="3" y="11" rx="2" ry="2"></rect>
            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
         </svg>
         Pagar
      </button>
   </div>
</form>

</> : <>
<div class="sc-538d1dac-11 bYfgDP">
      <div class="sc-538d1dac-10 lfBXpo"><span>Ao reservar este pedido declaro ter lido e concordado com os&nbsp;<a href="https://eurifei.com.br/ajuda/outros/termos-de-uso" target="_blank" rel="noopener noreferrer" aria-label="Abrir termos de uso em nova aba">termos de uso</a>&nbsp;e a&nbsp;<a href="https://eurifei.com.br/ajuda/outros/politica-de-privacidade" target="_blank" rel="noopener noreferrer" aria-label="Abrir política de privacidade em nova aba">política de privacidade</a>, e estou ciente que o Rifei NÃO se responsabiliza pelo pedido.</span></div>
      <button type="button" aria-label="Reservar" className={this.state.rsv == true ? "sc-688eee80-0 eBXdvs load" : "sc-688eee80-0 eBXdvs"} onClick={()=>{
     this.setState({rsv:true})
        fetch(`${apiUrl}/rifinha/orders`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               
                  "raffle_id":this.state.data.id,
                  "buyer":{
                     "id":this.state.manual == false ? this.state.userData.id : undefined,
                     "name":this.state.userData.nome,
                     "phone":this.state.manual == false ? this.state.userData.telefone : this.state.cfPhone.replace(/\D/g, ''),
                     "email":this.state.manual == true ? this.state.userData.email : undefined,
                     "document":this.state.manual == true ? this.state.userData.documento : undefined,
                     "save_data":this.state.manual == false ? true : this.state.svu
                  },
                  "numbers":this.state.data.cart,
                  "payment_method":this.state.PayMethod
               
            })
          })
            .then(response => {
               if (response.status === 422) {
                  this.setState({rsv:false})
               }
               if (response.status === 502) {
                  this.setState({rsv:false})
               }
              if (response.status === 403) {
             response.json().then(data=>{
               this.setState({rsv:false})
               toast.error(data.errors[0].message, {
                  position: "top-center",
                  autoClose: 5000, // Fecha automaticamente após 3 segundos
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });

             })
              }
              if(response.status == 404){
               this.setState({rsv:false})
              }
              if (response.status === 200) {
response.json().then(data=>{
this.props.navigate(`${window.location.pathname}/${data.code}`)
})
        
             
      }
            })
            .then(data => {
             
               
            
              
            })
            .catch(error => {
               this.setState({rsv:false})
              console.error('Erro:', error);
            });
      }} disabled={this.state.rsv == true ? true : false}>
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-lock ">
            <rect width="18" height="11" x="3" y="11" rx="2" ry="2"></rect>
            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
         </svg>
         Reservar
      </button>
   </div>
</>}
 

</div>

   
   </>)

      break;
   }
}


  render() {
   if(this.state.Loader){
      return(<>
         <div class="sc-179422ed-0"  style={{ display: 'flex', alignItems: 'center', height: '100vh',justifyContent:'center' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       </div>
      </>)
   }



    return (
  <>
  
<div class="sc-4ae1cb19-0 iGSLuZ">
   <div class="sc-4ae1cb19-1 fxkEEM">
      <a href="https://eurifei.com.br" rel="noopener noreferrer" class="sc-4ae1cb19-2 kCCMnO">
  EuRifei
      </a>
      <div class="sc-4ae1cb19-3 fKpPHi">
         <div  className={this.state.activeItems.includes('a') ? 'sc-4ae1cb19-4 hlSNsu ' : 'sc-4ae1cb19-4 liUYK'}>
            <div class="sc-4ae1cb19-5 ifFuTU">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="8" r="5"></circle>
                  <path d="M20 21a8 8 0 1 0-16 0"></path>
               </svg>
            </div>
            <p>Dados pessoais</p>
         </div>

         <div class="sc-4ae1cb19-6 jTtORl">
            <div class="sc-4ae1cb19-7 bNKgwr"></div>
         </div>

         <div className={this.state.activeItems.includes('b') ? 'sc-4ae1cb19-4 hlSNsu' : 'sc-4ae1cb19-4 liUYK'}>
  
   <div class="sc-4ae1cb19-5 ifFuTU">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" stroke="currentColor" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <rect width="20" height="12" x="2" y="6" rx="2"></rect>
         <circle cx="12" cy="12" r="2"></circle>
         <path d="M6 12h.01M18 12h.01"></path>
      </svg>
   </div>
   <p>Pagamento</p>
         </div>
         <div class="sc-4ae1cb19-6 jTtORl">
            <div class="sc-4ae1cb19-7 bNKgwr"></div>
         </div>
         <div className={this.state.activeItems.includes('c') ? 'sc-4ae1cb19-4 hlSNsu' : 'sc-4ae1cb19-4 liUYK '}>
            <div class="sc-4ae1cb19-5 ifFuTU">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text ">
                  <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" x2="8" y1="13" y2="13"></line>
                  <line x1="16" x2="8" y1="17" y2="17"></line>
                  <line x1="10" x2="8" y1="9" y2="9"></line>
               </svg>
            </div>
            <p>Revisar</p>
         </div>
      </div>
   </div>
</div>

<main>

   <div class="sc-538d1dac-0 hcRpAv">
 

   <div class="sc-538d1dac-2 cLZjva">
      {this.Show(this.state.page)}
   </div>
  


   <div class="sc-f072f39c-0 hMpREF">
   <div class="sc-f072f39c-1 esSgF">
   <div class="sc-f072f39c-2 elurGv">
   <div style={{ width: '60px', minWidth: '60px', minHeight: '60px' }}>
{this.state.data.imagem.length > 0 ? <>
   <div class="sc-f072f39c-4 evaxBY"><img src={`${apiUrl}/static/${this.state.data.imagem[0].name}`} alt="Desafio Léo Pedalando pelo Mundo" loading="lazy" decoding="async"/></div>
   
</> : <>
<div class="sc-f072f39c-4 evaxBY"><img src="https://rifei.imgix.net/storage/images/no-image.jpg?w=48&h=48&fit=crop&auto=format,compress" alt="Desafio Léo Pedalando pelo Mundo" loading="lazy" decoding="async"/></div>


</>}
           </div>
      <div class="sc-f072f39c-3 kNtCOb">
         <p>{this.state.data.title}</p>
         {this.state.data.cart != undefined ? <>
            <span>Quantidade: <b>{this.state.data.cart.length}</b></span>
         </> : <>
         <span>Quantidade: <b>{this.state.data.numbers}</b></span>
         </>}
         
      </div>
   </div>
   <div class="sc-f072f39c-10 gIQmLe">
      {this.state.data.cart != undefined && <>
   <div class="sc-501655dd-0 jbvgZV"><span>Bilhete selecionado:</span></div>

         {this.state.data.cart.map((item,index)=><>
      <div class="sc-f072f39c-11 lhYgiA" aria-label="028"><span>{item}</span></div>
     </>)}
      </>}
    
   </div>
   <div class="sc-f072f39c-5 dhBgMh">
      <div class="sc-f072f39c-6 cQOqid">
         <div class="sc-f072f39c-7 gvhgET">
            <div class="sc-f072f39c-8 jKDTeY">
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle ">
                  <circle cx="12" cy="12" r="10"></circle>
               </svg>
            </div>
            <span>Subtotal</span>
         </div>
         <div class="sc-f072f39c-9 jTcwie"><span>{this.state.data.subtotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></div>
      </div>
      <div class="sc-f072f39c-6 cQOqid">
         <div class="sc-f072f39c-7 gvhgET total">
            <div class="sc-f072f39c-8 jKDTeY">
               <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign ">
                  <line x1="12" x2="12" y1="2" y2="22"></line>
                  <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
               </svg>
            </div>
            <span>Total</span>
         </div>
         <div class="sc-f072f39c-9 jTcwie">
            <h4>{this.state.data.subtotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h4>
         </div>
      </div>
   </div>
</div>

   </div>
   </div>
   <div class="sc-538d1dac-17 jzbxYi">
   <div class="sc-538d1dac-18 cBnSBy">
      <div class="sc-538d1dac-19 fsegge">
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-lock ">
            <rect width="18" height="11" x="3" y="11" rx="2" ry="2"></rect>
            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
         </svg>
         <div class="sc-538d1dac-20 cAOCBI">
            <h3>CHECKOUT</h3>
            <span>100% SEGURO</span>
         </div>
      </div>
   </div>
</div>

   
</main>
  
  <ToastContainer/>
  </>
    );
  }
}

export default ChecOutR;