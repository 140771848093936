import React from "react"
import { Route, Routes, Router, useNavigate} from "react-router-dom"
import Inicio from "./paginas/Inicio";
import "../src/estilo.css"
import Precos from "./paginas/Precos";
import Page404 from "./paginas/Page404";
import SobreNos from "./paginas/SobreNos";
import CheckOut from "./paginas/CheckOut"
import CheckOutR from "./paginas/CheckOutR"
import Finish from "./paginas/Finish";
import Rifinhas from "./paginas/Rifinhas";
import FinishR from "./paginas/FinishR";
import PerguntasFqt from "./paginas/PerguntasFqt";
import Termos from "./paginas/Termos";
import PoliticaP from "./paginas/PoliticaP";
import ScrollToTop from "./Comps/ScrollToTop";
import View from "./paginas/View";
function App() {
  const navigateTo = useNavigate();

  return (
    <>
     <ScrollToTop/>
    <Routes>
    <Route exact  path="/"  element={<Inicio/>} />
   
    <Route  path="/precos"  element={<Precos/>} />
    <Route  path="/perguntas-frequente"  element={<PerguntasFqt/>} />
    <Route  path="/sobre-nos"  element={<SobreNos/>} />
    <Route  path="/rifinha/*"  element={<Rifinhas navigate={navigateTo}/>} />
    <Route  path="/ajuda/outros/termos-de-uso"  element={<Termos/>} />
    <Route  path="/ajuda/outros/politica-de-privacidade"  element={<PoliticaP/>} />
    <Route  path="/:id/checkout"  element={<CheckOut navigate={navigateTo}/>} />
    <Route  path="/rifinha/:id/checkout"  element={<CheckOutR navigate={navigateTo}/>} />

    <Route  path="/:id/checkout/:id"  element={<Finish/>} />
    <Route  path="/rifinha/:id/checkout/:id"  element={<FinishR/>} />
    <Route path="*" element={<View navigate={navigateTo}/>} />
    </Routes>
    </>
  );
}

export default App;
