import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Slideshow from '../../../Comps/SliderShow';
class Section4 extends React.Component {
  render() {
    return (
 <>
 
<section class="sc-2c403d08-22 vragC">
<div class="sc-2c403d08-8 klLsrI">
  <Slideshow slides={[
    {
      item:()=><>
     
     <div data-index="0" class="slick-slide slick-active slick-current" tabindex="-1" aria-hidden="false" >
   <div>
      <div tabindex="-1"  class="sc-2c403d08-25 cKBTIt">
         <div class="sc-2c403d08-26 gwQKGW">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-settings ">
               <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path>
               <circle cx="12" cy="12" r="3"></circle>
            </svg>
         </div>
         <h4>Desenvolvimento próprio</h4>
         <p>Plataforma desenvolvida por nós, com tecnologia de ponta. Não utilizamos sites prontos ou WordPress.</p>
      </div>
   </div>
</div>

<div data-index="1" class="slick-slide slick-active" tabindex="-1" aria-hidden="false" >
   <div>
      <div tabindex="-1"  class="sc-2c403d08-25 cKBTIt">
         <div class="sc-2c403d08-26 gwQKGW">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-gift ">
               <rect x="3" y="8" width="18" height="4" rx="1"></rect>
               <path d="M12 8v13"></path>
               <path d="M19 12v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7"></path>
               <path d="M7.5 8a2.5 2.5 0 0 1 0-5A4.8 8 0 0 1 12 8a4.8 8 0 0 1 4.5-5 2.5 2.5 0 0 1 0 5"></path>
            </svg>
         </div>
         <h4>Bilhetes premiados</h4>
         <p>Defina bilhetes premiados e veja o status a qualquer momento.</p>
      </div>
   </div>
</div>

<div data-index="2" class="slick-slide slick-active" tabindex="-1" aria-hidden="false" >
   <div>
      <div tabindex="-1"  class="sc-2c403d08-25 cKBTIt">
         <div class="sc-2c403d08-26 gwQKGW">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor">
               <path d="M7.5 7.67001V6.70001C7.5 4.45001 9.31 2.24001 11.56 2.03001C14.24 1.77001 16.5 3.88001 16.5 6.51001V7.89001" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
               <path d="M8.99999 22H15C19.02 22 19.74 20.39 19.95 18.43L20.7 12.43C20.97 9.99 20.27 8 16 8H7.99999C3.72999 8 3.02999 9.99 3.29999 12.43L4.04999 18.43C4.25999 20.39 4.97999 22 8.99999 22Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
               <path d="M15.4955 12H15.5045" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
               <path d="M8.49451 12H8.50349" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
         </div>
         <h4>Pagamentos automáticos</h4>
         <p>Receba pagamentos automáticos via PIX ou cartão de crédito.</p>
      </div>
   </div>
</div>

      </>
    },{
      item:()=><>
     
     <div tabindex="-1" class="sc-2c403d08-25 cKBTIt"><div class="sc-2c403d08-26 gwQKGW"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15 3C16.95 8.84 16.95 15.16 15 21" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001" stroke-linecap="round" stroke-linejoin="round"></path></svg></div><h4>Domínio próprio</h4><p>Adicione o seu próprio domínio e deixe o seu site ainda mais profissional.</p></div>
     <div tabindex="-1"  class="sc-2c403d08-25 cKBTIt"><div class="sc-2c403d08-26 gwQKGW"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-percent "><line x1="19" x2="5" y1="5" y2="19"></line><circle cx="6.5" cy="6.5" r="2.5"></circle><circle cx="17.5" cy="17.5" r="2.5"></circle></svg></div><h4>Promoções e cupons</h4><p>Crie promoções e cupons de desconto para alavancar ainda mais as suas vendas.</p></div>
     <div tabindex="-1"  class="sc-2c403d08-25 cKBTIt"><div class="sc-2c403d08-26 gwQKGW"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"><path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22" stroke-linecap="round" stroke-linejoin="round"></path></svg></div><h4>Afiliados</h4><p>Adicione afiliados para ajudar a vender a sua campanha.</p></div>
      </>
    },{
      item:()=><>
     <div tabindex="-1"  class="sc-2c403d08-25 cKBTIt"><div class="sc-2c403d08-26 gwQKGW"><svg width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5"><path d="M9.5 19.5V18H4.5C3.95 18 3.45 17.78 3.09 17.41C2.72 17.05 2.5 16.55 2.5 16C2.5 14.97 3.3 14.11 4.31 14.01C4.37 14 4.43 14 4.5 14H19.5C19.57 14 19.63 14 19.69 14.01C20.17 14.05 20.59 14.26 20.91 14.59C21.32 14.99 21.54 15.56 21.49 16.18C21.4 17.23 20.45 18 19.39 18H14.5V19.5C14.5 20.88 13.38 22 12 22C10.62 22 9.5 20.88 9.5 19.5Z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M20.17 5.3L19.69 14.01C19.63 14 19.57 14 19.5 14H4.50001C4.43001 14 4.37001 14 4.31001 14.01L3.83001 5.3C3.65001 3.53 5.04001 2 6.81001 2H17.19C18.96 2 20.35 3.53 20.17 5.3Z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7.98999 2V7" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 2V4" stroke-linecap="round" stroke-linejoin="round"></path></svg></div><h4>Aparência</h4><p>Personalize suas campanhas como nunca antes. Adicione sua logotipo, imagens do prêmio, altere as cores e muito mais. Experimente agora!</p></div> 
     <div tabindex="-1"  class="sc-2c403d08-25 cKBTIt"><div class="sc-2c403d08-26 gwQKGW"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"><path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.5 18.5C16.6 18.5 17.5 17.6 17.5 16.5V7.5C17.5 6.4 16.6 5.5 15.5 5.5C14.4 5.5 13.5 6.4 13.5 7.5V16.5C13.5 17.6 14.39 18.5 15.5 18.5Z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.5 18.5C9.6 18.5 10.5 17.6 10.5 16.5V13C10.5 11.9 9.6 11 8.5 11C7.4 11 6.5 11.9 6.5 13V16.5C6.5 17.6 7.39 18.5 8.5 18.5Z" stroke-linecap="round" stroke-linejoin="round"></path></svg></div><h4>Maiores compradores</h4><p>Veja a qualquer momento quem são os usuários que mais compram bilhetes em sua campanha.</p></div>
     <div tabindex="-1"  class="sc-2c403d08-25 cKBTIt"><div class="sc-2c403d08-26 gwQKGW"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor"><path d="M7.5 7.67001V6.70001C7.5 4.45001 9.31 2.24001 11.56 2.03001C14.24 1.77001 16.5 3.88001 16.5 6.51001V7.89001" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.99999 22H15C19.02 22 19.74 20.39 19.95 18.43L20.7 12.43C20.97 9.99 20.27 8 16 8H7.99999C3.72999 8 3.02999 9.99 3.29999 12.43L4.04999 18.43C4.25999 20.39 4.97999 22 8.99999 22Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.4955 12H15.5045" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.49451 12H8.50349" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></div><h4>Pagamentos automáticos</h4><p>Receba pagamentos automáticos via PIX ou cartão de crédito.</p></div>
       </>
    }
  ]}  interval={5000}/>
</div>
</section>



 </>
    );
  }
}

export default Section4;