import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import homem from "./home-support-image.webp"
import "./style.css"
import img1 from "../../../images/1.webp"
import img2 from "../../../images/2.webp"
import img3 from "../../../images/3.webp"
import img4 from "../../../images/4.webp"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
class Section7 extends React.Component {
  render() {
    return (
 <>
 

 <section id="steps" class="steps">
   <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
      <div class="max-w-2xl md:mx-auto text-center xl:max-w-none">
         <h2 class="font-display text-3xl max-w-3xl mx-auto tracking-tight sm:text-4xl">Veja como é simples.</h2>
         <p class="mt-4 text-lg tracking-tight max-w-2xl mx-auto"> Praticidade e simplicidade para criar com tranquilidade as suas campanhas </p>
      </div>
      <div class="vjj">
         <div class="2xs:text-center">
            <img class="w-14 md:w-24 lg:w-32 2xs:mx-auto" alt="Imagem do painel" src={img1}/>
            <h3 class="font-display text-xl mb-4 mt-4">Crie</h3>
            <p class="">Preencha os campos de sua campanha</p>
         </div>
         <div class="2xs:text-center">
            <img class="w-14 md:w-24 lg:w-32 2xs:mx-auto" src={img2} alt="Imagem do painel"/>
            <h3 class="font-display text-xl mb-4 mt-4">Publique</h3>
            <p class="">Pague a taxa de publicação</p>
         </div>
         <div class="2xs:text-center">
            <img class="w-14 md:w-24 lg:w-32 2xs:mx-auto" src={img3} alt="Imagem do painel"/>
            <h3 class="font-display text-xl mb-4 mt-4">Compartilhe</h3>
            <p class="">Compartilhe o seu link</p>
         </div>
         <div class="2xs:text-center">
            <img class="w-14 md:w-24 lg:w-32 2xs:mx-auto" src={img4} alt="Imagem do painel"/>
            <h3 class="font-display text-xl mb-4 mt-4">Arrecade</h3>
            <p class="">Toda a arrecadação vai para você!</p>
         </div>
      </div>
   </div>
</section>





 </>
    );
  }
}

export default Section7;