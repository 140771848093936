import React from 'react';
import "./new.css"
import { apiUrl } from '../Config';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import Slider from '../../Comps/Slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faMinus,faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import Modal from '../../Comps/Modal';
class View extends React.Component {
    constructor(props) {
       super(props);
       this.state = {
        Loader:true,
        selectedButtons: [],
        imagem:[],
        manualSlc:1,
        itemsToShow: 100, // Inicialmente, mostra 10 itens
        initialItemsToShow: 100 
       }
    }
    componentDidMount(){
        document.title = "Carregando..."
        var url = window.location.href;
        var pastaAtual = url.substring(url.lastIndexOf("/") + 1);
        fetch(`${apiUrl}/raffles/list/`+pastaAtual, {
            method: 'GET',
            credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
            headers: {
              'Authorization': 'Bearer '+Cookies.get('auth_user'), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            }
          }).then(response=>{
            response.json().then(result=>{
                if(result.status == true){
                    document.title = result.title;
                    this.setState({data:result,Loader:false,err:false}) 

         if(result.imagem.length > 0){
            var Object = [];
            result.imagem.forEach((row) => {
            Object.push({
               image:`${apiUrl}/static/${row.name}`
            })
            })
            this.setState({imagem:Object})
         }else{
            this.setState({imagem:[]})
         }

                }else{
                    document.title = "Página não encontrada!"
                    this.setState({data:result,Loader:false,err:true}) 
                }
            })
          })
    }
    handleShowMore = () => {
      this.setState(prevState => ({
        itemsToShow: prevState.itemsToShow + 100 // Mostra mais 10 itens quando o botão é clicado
      }));
    };
    handleClick = (index) => {
        this.setState((prevState) => {
          if (prevState.selectedButtons.includes(index)) {
            return {
              selectedButtons: prevState.selectedButtons.filter(
                (buttonIndex) => buttonIndex !== index
              ),
            };
          } else {
            return {
              selectedButtons: [...prevState.selectedButtons, index],
            };
          }
        });
      };
    render(){
        if(this.state.Loader){
            return(<>
               <div class="sc-179422ed-0"  style={{ display: 'flex', alignItems: 'center', height: '100vh',justifyContent:'center' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
                   <rect x="0" y="0" width="100" height="100" fill="none"></rect>
                   <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
                   <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                      <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                      <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
                   </circle>
                </svg>
             </div>
            </>)
         }
         if(this.state.err){
            return(<>
            <main class="sc-de8cbe2d-0 iypOTp">
   <div class="sc-de8cbe2d-1 hCroVH">
      <div class="sc-de8cbe2d-3 kXRTMm">
         <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="currentColor" viewBox="0 0 1024 1024">
            <defs>
               <linearGradient id="_element_656" gradientUnits="userSpaceOnUse" x1="512" y1="1024.079" x2="512" y2="-0.079">
                  <stop offset="0" stop-color="#fb8b00"></stop>
                  <stop offset="1" stop-color="#ffc47b"></stop>
               </linearGradient>
               <linearGradient id="_element_464" gradientUnits="userSpaceOnUse" x1="109.066" y1="348.433" x2="109.066" y2="146.828">
                  <stop offset="0" stop-color="#0070fb"></stop>
                  <stop offset="1" stop-color="#7acbff"></stop>
               </linearGradient>
            </defs>
            <path fill="url(#_element_656)" d="M379.48 17.45C652.62 -55.74 933.37 106.35 1006.55 379.48C1079.74 652.62 917.65 933.37 644.52 1006.55C371.38 1079.74 90.63 917.65 17.45 644.52C-55.74 371.38 106.35 90.63 379.48 17.45Z"></path>
            <path fill="none" stroke="#000000" stroke-linecap="round" stroke-width="57" d="M244.46 599.22C244.46 599.22 235.86 545.62 295.2 529.72C353.01 514.23 371.96 565.05 371.96 565.05"></path>
            <path fill="none" stroke="#000000" stroke-linecap="round" stroke-width="57" d="M659.81 487.92C659.81 487.92 651.21 434.32 710.55 418.42C768.36 402.93 787.31 453.76 787.31 453.76"></path>
            <path fill="#000000" d="M557.55 682C614.17 666.83 649.47 705.85 644.68 713.53C641.24 719.05 610.88 700.84 565.83 712.91C524.32 724.04 505.89 756.33 498.83 752.61C490.38 748.16 500.71 697.23 557.55 682Z"></path>
            <path fill="url(#_element_464)" d="M99.54 156.59C158.71 190.76 249.14 310.84 126.3 343.75C2.57 376.91 22.48 225.67 56.42 166.88C70.38 142.7 73.95 141.82 99.54 156.59Z"></path>
         </svg>
      </div>
      <div class="sc-de8cbe2d-2 bAAQGt">
         <h1 class="sc-de8cbe2d-4 hZNJuz">Esta página não existe</h1>
         <p class="sc-de8cbe2d-5 kPXVax">Desculpe, a página que você está procurando não existe ou foi movida.</p>
      </div>
      <div class="sc-de8cbe2d-6 BJfFs">
    <Link to="/" class="sc-688eee80-0 ivGZii">Retornar ao início</Link></div>
   </div>
</main>
            </>)
         }
        return(<>
<header class="sc-d15d4f87-0 fMFqRX header-nav-fixed">
   <nav aria-label="Menu principal" class="sc-d15d4f87-1 gCnxGZ">
      <div class="sc-d15d4f87-2 deffc header-mobile-menu">
         <header></header>
         <ul class="navigation">
            <li class="navigation-item">
               <button type="button" aria-label="Ver meus bilhetes">
                  <div>
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart ">
                        <circle cx="8" cy="21" r="1"></circle>
                        <circle cx="19" cy="21" r="1"></circle>
                        <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
                     </svg>
                  </div>
                  <span>Ver meus bilhetes</span>
               </button>
            </li>
           
            <li class="navigation-item">
               <a href={`https://wa.me/${this.state.data.by_created.phone}`} target="_blank" rel="noopener noreferrer" aria-label="Contato">
                  <div>
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
                     </svg>
                  </div>
                  <span>Contato</span>
               </a>
            </li>
         </ul>
         <div class="sc-d15d4f87-3 esZBWS">
         <a href={`whatsapp://send?text=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no WhatsApp" class="sc-84a33c3d-1 kzXqZS">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 24 24">
               <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
            </svg>
         </a>
         <a href={`https://facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Facebook" class="sc-84a33c3d-1 dXNPhl">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook ">
               <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
            </svg>
         </a>
         <a href={`https://t.me/share/url?url=${window.location.href}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Telegram" class="sc-84a33c3d-1 yDVKT">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
               <path d="m5.9 13.5l-5.4-1.7c0 0-0.7-0.3-0.5-0.9 0.1-0.1 0.2-0.2 0.4-0.4 1.2-0.8 22.3-8.4 22.3-8.4 0 0 0.6-0.2 0.9-0.1q0.1 0.1 0.1 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0 0.1 0 0.1 0 0.2 0 0 0 0.1 0 0 0 0.1c0 0.1 0 0.3 0 0.5-0.2 2-4 17.5-4 17.5 0 0-0.2 0.9-1 0.9q-0.2 0-0.3 0-0.2 0-0.3-0.1-0.2-0.1-0.3-0.1-0.1-0.1-0.2-0.2c-1.7-1.4-7.2-5.2-8.5-6q0 0 0 0 0 0 0-0.1 0 0 0 0-0.1 0-0.1 0c0-0.1 0.1-0.2 0.1-0.2 0 0 9.7-8.7 10-9.6 0 0-0.1-0.1-0.2 0-0.6 0.2-11.8 7.2-13 8q-0.1 0-0.1 0 0 0-0.1 0 0 0 0 0-0.1 0-0.1 0z"></path>
            </svg>
         </a>
         <a href={`https://twitter.com/share?url=${window.location.href}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Twitter" class="sc-84a33c3d-1 daTCjo">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-twitter ">
               <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
            </svg>
         </a>
         </div>
      </div>
      <div class="sc-d15d4f87-5 cUWbSa header-container">
         <div class="sc-d15d4f87-6 gvOTnf">
            {this.state.data.personalize.logo != null ? <>
            <Link to="/" class="sc-da21b912-7 dtDsgB">
            <img src={`${apiUrl}/static/${this.state.data.personalize.logo}`}  />
            </Link>
         
            </> : <>
            <Link class="txt" to="/">EuRifei</Link>
            </>}
           
            <button type="button" aria-label="Abrir menu" aria-expanded="false" aria-haspopup="true" class="sc-d15d4f87-8 iVhvGR">
               <div class="sc-d15d4f87-9 cvqGmm">
                  <div class="sc-d15d4f87-10 dpdcxm header-hamburguer-line"></div>
                  <div class="sc-d15d4f87-10 dpdcxm header-hamburguer-line"></div>
               </div>
            </button>
         </div>
         <ul data-orientation="horizontal" dir="ltr" class="sc-d15d4f87-11 dAWJrA">
        
            <li class="sc-d15d4f87-12 gXNzsT">
               <a href={`https://wa.me/${this.state.data.phone}`} target="_blank" rel="noopener noreferrer" aria-label="Contato" class="sc-d15d4f87-13 bSdike">
                  <div >
                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
                     </svg>
                  </div>
                  Contato
               </a>
            </li>
         </ul>
      </div>
   </nav>
</header>
<section className='sc-3f9a15f1-2 eAApiE'>
<div className="lottery-details">
            <div className="image-container">
            {this.state.imagem.length > 0 ? <>
         <Slider slides={this.state.imagem}/>
      </> : <>
      <div tabindex="-1" class="sc-681247da-3 cuQzMG">
      <img src="https://rifei.imgix.net/storage/images/no-image.jpg?w=1080&amp;fit=max&amp;q=70&amp;auto=format,compress" alt="Sem imagem" />
   
      </div>
         </>}
            </div>
            <div className="details-container">
          <div class="inner">
          <h2>{this.state.data.title}</h2>
         <div class="valor">
         <span>Por apenas:</span>
         <div>{new Number(this.state.data.price).toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL'
})}</div>
            <hr/>
          </div>
          </div>
          <hr/>
          {
            this.state.data.promotion.length > 0 && <>
             <div class="inner">

<span className='title'>Promoções</span>
<div class="pmc">
{this.state.data.promotion.map((item)=><button class="">
 <span>{item.q}</span> bilhetes por {new Number(item.v).toLocaleString('pt-BR', {
 style: 'currency',
 currency: 'BRL',
})}  
</button>)}
</div>


          </div>

          <hr/>
            </>
          }
         

          <div class="inner">

<span className='title'>Redes sociais </span>
{Object.values(this.state.data.socials).every(value => value === null) ? <>
          
          
               <div class="sc-5122807c-0 fiBZHc">
               <a href={`whatsapp://send?text=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no WhatsApp" class="sc-84a33c3d-1 kzXqZS">
               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
               </svg>
            </a>
            <a href={`https://facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Facebook" class="sc-84a33c3d-1 dXNPhl">
               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook ">
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
               </svg>
            </a>
            <a href={`https://t.me/share/url?url=${window.location.href}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Telegram" class="sc-84a33c3d-1 yDVKT">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                  <path d="m5.9 13.5l-5.4-1.7c0 0-0.7-0.3-0.5-0.9 0.1-0.1 0.2-0.2 0.4-0.4 1.2-0.8 22.3-8.4 22.3-8.4 0 0 0.6-0.2 0.9-0.1q0.1 0.1 0.1 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0 0.1 0 0.1 0 0.2 0 0 0 0.1 0 0 0 0.1c0 0.1 0 0.3 0 0.5-0.2 2-4 17.5-4 17.5 0 0-0.2 0.9-1 0.9q-0.2 0-0.3 0-0.2 0-0.3-0.1-0.2-0.1-0.3-0.1-0.1-0.1-0.2-0.2c-1.7-1.4-7.2-5.2-8.5-6q0 0 0 0 0 0 0-0.1 0 0 0 0-0.1 0-0.1 0c0-0.1 0.1-0.2 0.1-0.2 0 0 9.7-8.7 10-9.6 0 0-0.1-0.1-0.2 0-0.6 0.2-11.8 7.2-13 8q-0.1 0-0.1 0 0 0-0.1 0 0 0 0 0-0.1 0-0.1 0z"></path>
               </svg>
            </a>
            <a href={`https://twitter.com/share?url=${window.location.href}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Twitter" class="sc-84a33c3d-1 daTCjo">
               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-twitter ">
                  <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
               </svg>
            </a>
            </div>
           
           </> : <>
       
               <div class="sc-5122807c-0 fiBZHc">

{this.state.data.socials.facebook != null && <>
<a href={`https://facebook.com/${this.state.data.socials.facebook}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Facebook" class="sc-84a33c3d-1 dXNPhl">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook ">
               <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
            </svg>
</a>
</>}



{this.state.data.socials.instagram != null && <>
   <a href={`https://instagram.com/${this.state.data.socials.instagram}`} target="_blank" rel="noopener noreferrer" aria-label="Instagram" class="sc-5122807c-1 cWDpth">
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-instagram ">
      <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
      <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
   </svg>
</a>

</>}

{this.state.data.socials.tiktok != null && <>
   <a href={`https://tiktok.com/@${this.state.data.socials.tiktok}`} target="_blank" rel="noopener noreferrer" aria-label="TikTok" className="sc-5122807c-1 dlVKxp2">
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" style={{ zIndex: 2 }}>
    <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#24f6f0" style={{ position: 'absolute', top: '8px', left: '8px', zIndex: 1 }}>
    <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fe2d52" style={{ position: 'absolute', bottom: '8px', right: '8px', zIndex: 0 }}>
    <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
  </svg>
</a>
</>}


{this.state.data.socials.ytb != null && <>
   <a href={`https://youtube.com/@${this.state.data.socials.ytb}`} target="_blank" rel="noopener noreferrer" aria-label="TikTok" className="sc-5122807c-1 cn-ytb">
   <svg height="800px" width="800px" version="1.1" id="Layer_1" viewBox="0 0 461.001 461.001"><g><path d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"></path></g></svg>
   </a>
</>}



        
               </div>
          
           </>}
</div>
            </div>
</div>
</section>
{true && <>
   <hr class="psn-hr"/>

<section className='sc-3f9a15f1-2 eAApiE' style={{marginTop:15}}>
<div class="sc-3f9a15f1-2 eAApiE">
   <div class="sc-3f9a15f1-13 byugCZ">
      <div>
         <div class="sc-3f9a15f1-28 kfFTzL line">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bar-chart">
               <line x1="12" x2="12" y1="20" y2="10"></line>
               <line x1="18" x2="18" y1="20" y2="4"></line>
               <line x1="6" x2="6" y1="20" y2="16"></line>
            </svg>
         </div>
      </div>
      <h2 class="sc-3f9a15f1-14 jQlWTy">Maiores compradores</h2>
   </div>
   <div class="sc-a878b30e-0 eqOZxq">
      <div class="sc-3f9a15f1-8 kOqdiR">
         <div class="sc-a878b30e-2 iUYSTZ">
            <div>
               <div class="sc-a878b30e-1 jiRXSi">
                  <h3>
                     1º
                  </h3>
               </div>
            </div>
            <div class="sc-a878b30e-3 gFShlj">
               <h4>Jhonatan da Costa Nascimento</h4>
               <span>
                  Bilhetes: <b>3751</b>
               </span>
            </div>
         </div>
         <div class="sc-a878b30e-2 iUYSTZ">
            <div>
               <div class="sc-a878b30e-1 jiRXSi">
                  <h3>
                     2º
                  </h3>
               </div>
            </div>
            <div class="sc-a878b30e-3 gFShlj">
               <h4>Larissa dos Santos</h4>
               <span>
                  Bilhetes: <b>1856</b>
               </span>
            </div>
         </div>
         <div class="sc-a878b30e-2 iUYSTZ">
            <div>
               <div class="sc-a878b30e-1 jiRXSi">
                  <h3>
                     3º
                  </h3>
               </div>
            </div>
            <div class="sc-a878b30e-3 gFShlj">
               <h4>Brandon llen Oliveira Silva</h4>
               <span>
                  Bilhetes: <b>1083</b>
               </span>
            </div>
         </div>
         <div class="sc-a878b30e-2 iUYSTZ">
            <div>
               <div class="sc-a878b30e-1 jiRXSi">
                  <h3>
                     4º
                  </h3>
               </div>
            </div>
            <div class="sc-a878b30e-3 gFShlj">
               <h4>Lisdayane Ferreira de Souza</h4>
               <span>
                  Bilhetes: <b>1050</b>
               </span>
            </div>
         </div>
         <div class="sc-a878b30e-2 iUYSTZ">
            <div>
               <div class="sc-a878b30e-1 jiRXSi secondary">
                  <h3>
                     5º
                  </h3>
               </div>
            </div>
            <div class="sc-a878b30e-3 gFShlj">
               <h4>Kelly Cristina aires de oliveira</h4>
               <span>
             <b>850</b>
               </span>
            </div>
         </div>
      </div>
   </div>
</div>

<div class="sc-3f9a15f1-2 eAApiE top-container" style={{marginTop:-35}}>
   <div class="sc-3f9a15f1-13 byugCZ">
      <div>
         <div class="sc-3f9a15f1-28 kfFTzL line">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-star">
               <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
            </svg>
         </div>
      </div>
      <h2 class="sc-3f9a15f1-14 jQlWTy">Bilhetes premiados</h2>
   </div>
   <div class="sc-f8df8839-0 gpIMyi">
      <div class="sc-3f9a15f1-8 kOqdiR">
         <div class="sc-8e721470-0 jJuTYa">
            <div class="sc-8e721470-1 gaogiP overflow">
               <div class="sc-f8df8839-1 duSyVe">
                  <div class="sc-f8df8839-2 ezaSAo">
                     <div class="sc-f8df8839-3 ixwxcS">
                        <div class="sc-f8df8839-4 bpNMYI">
                           <div class="sc-f8df8839-5 iTQGsk"><span>414854</span></div>
                           <div class="sc-f8df8839-6 CvUDt"></div>
                           <div class="sc-f8df8839-5 iTQGsk available">
                              <div class="sc-f8df8839-7 leYMmo">
                                 <div class="sc-f8df8839-8 ebkIKN">Disponível</div>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="#25bb6f" stroke="#25bb6f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle">
                                    <circle cx="12" cy="12" r="10"></circle>
                                 </svg>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="sc-f8df8839-9 ilyQoj">
                     <div class="sc-f8df8839-11 dAQmkn"><span class="sc-f8df8839-10 hogOEA">500 REAIS</span></div>
                  </div>
               </div>
               <div class="sc-f8df8839-1 duSyVe">
                  <div class="sc-f8df8839-2 ezaSAo">
                     <div class="sc-f8df8839-3 ixwxcS">
                        <div class="sc-f8df8839-4 bpNMYI">
                           <div class="sc-f8df8839-5 iTQGsk"><span>523212</span></div>
                           <div class="sc-f8df8839-6 CvUDt"></div>
                           <div class="sc-f8df8839-5 iTQGsk available">
                              <div class="sc-f8df8839-7 leYMmo">
                                 <div class="sc-f8df8839-8 ebkIKN">Disponível</div>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="#25bb6f" stroke="#25bb6f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle">
                                    <circle cx="12" cy="12" r="10"></circle>
                                 </svg>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="sc-f8df8839-9 ilyQoj">
                     <div class="sc-f8df8839-11 dAQmkn"><span class="sc-f8df8839-10 hogOEA">500 REAIS</span></div>
                  </div>
               </div>
               <div class="sc-f8df8839-1 duSyVe">
                  <div class="sc-f8df8839-2 ezaSAo">
                     <div class="sc-f8df8839-3 ixwxcS">
                        <div class="sc-f8df8839-4 bpNMYI">
                           <div class="sc-f8df8839-5 iTQGsk"><span>694848</span></div>
                           <div class="sc-f8df8839-6 CvUDt"></div>
                           <div class="sc-f8df8839-5 iTQGsk available">
                              <div class="sc-f8df8839-7 leYMmo">
                                 <div class="sc-f8df8839-8 ebkIKN">Disponível</div>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="#25bb6f" stroke="#25bb6f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle">
                                    <circle cx="12" cy="12" r="10"></circle>
                                 </svg>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="sc-f8df8839-9 ilyQoj">
                     <div class="sc-f8df8839-11 dAQmkn"><span class="sc-f8df8839-10 hogOEA">500 REAIS</span></div>
                  </div>
               </div>
               <div class="sc-f8df8839-1 duSyVe">
                  <div class="sc-f8df8839-2 ezaSAo">
                     <div class="sc-f8df8839-3 ixwxcS">
                        <div class="sc-f8df8839-4 bpNMYI">
                           <div class="sc-f8df8839-5 iTQGsk"><span>786348</span></div>
                           <div class="sc-f8df8839-6 CvUDt"></div>
                           <div class="sc-f8df8839-5 iTQGsk available">
                              <div class="sc-f8df8839-7 leYMmo">
                                 <div class="sc-f8df8839-8 ebkIKN">Disponível</div>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="#25bb6f" stroke="#25bb6f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle">
                                    <circle cx="12" cy="12" r="10"></circle>
                                 </svg>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="sc-f8df8839-9 ilyQoj">
                     <div class="sc-f8df8839-11 dAQmkn"><span class="sc-f8df8839-10 hogOEA">500 REAIS</span></div>
                  </div>
               </div>
               <div class="sc-f8df8839-1 duSyVe">
                  <div class="sc-f8df8839-2 ezaSAo">
                     <div class="sc-f8df8839-3 ixwxcS">
                        <div class="sc-f8df8839-4 bpNMYI">
                           <div class="sc-f8df8839-5 iTQGsk"><span>997994</span></div>
                           <div class="sc-f8df8839-6 CvUDt"></div>
                           <div class="sc-f8df8839-5 iTQGsk available">
                              <div class="sc-f8df8839-7 leYMmo">
                                 <div class="sc-f8df8839-8 ebkIKN">Disponível</div>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="#25bb6f" stroke="#25bb6f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle" >
                                    <circle cx="12" cy="12" r="10"></circle>
                                 </svg>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="sc-f8df8839-9 ilyQoj">
                     <div class="sc-f8df8839-11 dAQmkn"><span class="sc-f8df8839-10 hogOEA">500 REAIS</span></div>
                  </div>
               </div>
            </div>
            </div>
      </div>
   </div>
</div>

</section>

<hr class="psn-hr"/>
</>}




<section className='sc-3f9a15f1-2 eAApiE'>

{this.state.data.show_numbers == 1 ? <>


   <div class="sc-3f9a15f1-2 eAApiE" style={{marginTop:'25px'}}>
   <div class="sc-53133923-0 bUGvtM">
      <div class="sc-3f9a15f1-13 byugCZ">
         <div>
            <div class="sc-3f9a15f1-28 kfFTzL">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket">
                  <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path>
                  <path d="M13 5v2"></path>
                  <path d="M13 17v2"></path>
                  <path d="M13 11v2"></path>
               </svg>
            </div>
         </div>
         <div>
            <h1 class="sc-3f9a15f1-14 jQlWTy">Bilhetes</h1>
            <p class="sc-53133923-2 ctwzph">Selecione a quantidade que deseja comprar</p>
         </div>
      </div>
      <button type="button" class="sc-53133923-4 beyMgu search-numbers" onClick={()=>{
         
      }} aria-label="Ver meus bilhetes">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart">
            <circle cx="8" cy="21" r="1"></circle>
            <circle cx="19" cy="21" r="1"></circle>
            <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
         </svg>
         Ver meus bilhetes
      </button>
   </div>
   <div class="sc-53133923-8 dUqRXC numbers-card">
 <div class="sub">



{this.state.data.list.slice(0, this.state.itemsToShow).map((item,index)=><>

{item.status == "available" ? <>
<button className={this.state.selectedButtons.includes(index) ? 'sc-e1a39f58-8 gJdDMo ticket selected' : 'sc-e1a39f58-8 gJdDMo ticket'}  onClick={()=>{
   this.handleClick(index)
}}>{item.number}</button>

</> : <>
<button  className={`sc-e1a39f58-8 gJdDMo ticket ${item.status}`} onClick={()=>{
   Modal.create({titulo:`Bilhete #${item.number}`,html:<>
   <div class="sc-e1a39f58-17 cUeHHZ"><small>Participante:</small><p><b>{item.name}</b></p><small>Status:</small><p><b>{item.status == "approved" ? "Pago" : "Reservado"}</b></p></div>
   </>})
}}>{item.number}</button>
</>}

</>)}

 </div>
 {this.state.itemsToShow <= this.state.data.list.length && <>
   <button class="showm" onClick={this.handleShowMore}>Mostrar Mais</button>
 </>}

 <hr/>
    

<button className='ptcp' onClick={()=>{
    this.setState({ptc:true})
    fetch(`${apiUrl}/raffles/${this.state.data.id}/cart/`, {
       method: 'POST',
       credentials: 'include',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify({
        numbers:this.state.manualSlc
       })
     })
       .then(response => {
          if (response.status === 502) {
             this.setState({ptc:false})
          }
         if (response.status === 403) {
        response.json().then(data=>{
          
          toast.error(data.errors[0].message, {
             position: "top-center",
             autoClose: 5000, // Fecha automaticamente após 3 segundos
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
           });

        })
         }
         if (response.status === 200) {
           // Tratar resposta 200
           return response.json();
         }
       })
       .then(data => {
          this.setState({ptc:false})
        if(data.status == 1){
        this.props.navigate(`/${data.slug}/checkout`)
        }
         
       })
       .catch(error => {
          this.setState({ptc:false})
         console.error('Erro:', error);
       });
}}>Participar ({(this.state.selectedButtons.length * new Number(this.state.data.price)).toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL'
})})</button>

    
   </div>
   
</div>




</> : <>

<div class="sc-3f9a15f1-2 eAApiE">
   <div class="sc-53133923-0 bUGvtM">
      <div class="sc-3f9a15f1-13 byugCZ">
         <div>
            <div class="sc-3f9a15f1-28 kfFTzL">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket">
                  <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path>
                  <path d="M13 5v2"></path>
                  <path d="M13 17v2"></path>
                  <path d="M13 11v2"></path>
               </svg>
            </div>
         </div>
         <div>
            <h1 class="sc-3f9a15f1-14 jQlWTy">Bilhetes</h1>
            <p class="sc-53133923-2 ctwzph">Selecione a quantidade que deseja comprar</p>
         </div>
      </div>
      <button type="button" class="sc-53133923-4 beyMgu search-numbers" aria-label="Ver meus bilhetes">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart">
            <circle cx="8" cy="21" r="1"></circle>
            <circle cx="19" cy="21" r="1"></circle>
            <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
         </svg>
         Ver meus bilhetes
      </button>
   </div>
   <div class="sc-53133923-8 dUqRXC numbers-card">
      <div class="sc-53133923-9 ioCLjK numbers-card">
         <div class="sc-53133923-12 fpSmSj two-line"><button type="button" aria-label="Selecionar mais 1 bilhete(s)" class="sc-53133923-13 bGXQYi select-button"><span class="sc-53133923-14 fJMVbE">Selecionar</span><span class="sc-53133923-15 efMksn">1</span></button><button type="button" aria-label="Selecionar mais 5 bilhete(s)" class="sc-53133923-13 bGXQYi select-button"><span class="sc-53133923-14 fJMVbE">Selecionar</span><span class="sc-53133923-15 efMksn">5</span></button><button type="button" aria-label="Selecionar mais 25 bilhete(s)" class="sc-53133923-13 bGXQYi select-button"><span class="sc-53133923-14 fJMVbE">Selecionar</span><span class="sc-53133923-15 efMksn">25</span></button><button type="button" aria-label="Selecionar mais 50 bilhete(s)" class="sc-53133923-13 bGXQYi select-button"><span class="sc-53133923-14 fJMVbE">Selecionar</span><span class="sc-53133923-15 efMksn">50</span></button><button type="button" aria-label="Selecionar mais 100 bilhete(s)" class="sc-53133923-13 bGXQYi select-button most-popular"><span class="sc-53133923-14 fJMVbE">Selecionar</span><span class="sc-53133923-15 efMksn">100</span></button><button type="button" aria-label="Selecionar mais 500 bilhete(s)" class="sc-53133923-13 bGXQYi select-button"><span class="sc-53133923-14 fJMVbE">Selecionar</span><span class="sc-53133923-15 efMksn">500</span></button></div>
         <div class="sc-53133923-16 ioOFBX">
            <div class="sc-53133923-17 btEnJz">
               <button type="button" aria-label="Desselecionar 1 bilhete" class="sc-53133923-18 bHEhgA">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-minus">
                     <path d="M5 12h14"></path>
                  </svg>
               </button>
               <input aria-label="Quantidade de bilhetes" readonly="" class="sc-53133923-19 dlpaBp" value="50"/>
               <button type="button" aria-label="Selecionar mais 1 bilhete" class="sc-53133923-18 bHEhgA">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus">
                     <path d="M5 12h14"></path>
                     <path d="M12 5v14"></path>
                  </svg>
               </button>
            </div>
         </div>
      </div>
      <div class="sc-53133923-10 kJurDX">
         <div class="sc-53133923-11 fXZXsb">
            <div class="sc-53133923-20 bEVuxY"><button type="button" id="checkout-button" aria-label="Participar" class="sc-53133923-21 jueDvQ">Participar (R$&nbsp;1,80)</button></div>
         </div>
      </div>
   </div>
   
</div>


</>}

</section>
<ToastContainer/>

        </>)
    }
}


export default View;