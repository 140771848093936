import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./style.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import minhaImagem from "./image.png"
class Section2 extends React.Component {
  render() {
    return (
 <>
 

 <section class="sc-2c403d08-14 lioppJ">
   <div class="sc-2c403d08-6 bNlkwT">
    <span className='est1p'>
         <span className='est2p'>
    
        </span>
       
    </span>
   </div>
   <div class="sc-2c403d08-8 klLsrI main">
      <div class="sc-2c403d08-15 fJpltg"></div>
      <h2 class="sc-2c403d08-17 ifeGsO">Por que escolher a plataforma EuRifei?</h2>
      <ul class="sc-2c403d08-37 kBALcf">
         <li class="sc-2c403d08-38 lnmjtn a-1">
            <h4><span>+18</span> mil</h4>
            <div class="sc-2c403d08-39 cdOtXO"></div>
            <p>Campanhas criadas com a plataforma</p>
         </li>
         <li class="sc-2c403d08-38 lnmjtn a-2">
            <h4><span>+15</span> mi</h4>
            <div class="sc-2c403d08-39 cdOtXO"></div>
            <p>Pedidos através da plataforma</p>
         </li>
         <li class="sc-2c403d08-38 lnmjtn b-1">
            <h4><span>+10</span> mi</h4>
            <div class="sc-2c403d08-39 cdOtXO"></div>
            <p>Visualizações nos últimos 28 dias</p>
         </li>
         <li class="sc-2c403d08-38 lnmjtn b-2">
            <h4><span>+3</span> anos</h4>
            <div class="sc-2c403d08-39 cdOtXO"></div>
            <p>Atuando e inovando o mercado</p>
         </li>
      </ul>
   </div>
</section>



 </>
    );
  }
}

export default Section2;