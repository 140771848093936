import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./style.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import copy from 'clipboard-copy';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import socketIOClient from 'socket.io-client';
import logo from "../../../src/images/logo.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiUrl } from '../Config';
class Finish extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = {
           menu:false,
           Loader:true,
           data:[]
        }
        if (process.env.NODE_ENV === 'development') {
        this.socket = socketIOClient('http://rifa.com:2325');
        }else{
         this.socket = socketIOClient('https://api.eurifei.com');
        }
     }
     converterMinutosParaHoras(minutos) {
      const horas = Math.floor(minutos / 60);
      const minutosRestantes = minutos % 60;
      return `${horas} horas e ${minutosRestantes} minutos`;
  }
_Data = (dataOriginal) => {

let data = new Date(dataOriginal);

let dia = data.getDate().toString().padStart(2, "0");
let mes = (data.getMonth() + 1).toString().padStart(2, "0"); // Os meses começam em zero, por isso adicionamos 1
let ano = data.getFullYear();

let hora = data.getHours().toString().padStart(2, "0");
let minuto = data.getMinutes().toString().padStart(2, "0");
let segundo = data.getSeconds().toString().padStart(2, "0");

return  `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`
}


    setCookie(name, value, days) {
        var expires = '';  
        if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + value + expires + '; path=/';
      }
 
 
 
 
    componentDidMount(){
document.title = "Pagar pedido - EuRifei"  






let str =  window.location.pathname;
let lastIndex = str.lastIndexOf("/");
let result = str.substring(lastIndex + 1);




this.socket.on("connect", () => {
   this.socket.emit("pay",result);
 });

 this.socket.on('message', (message) => {
  if(message == "approved"){
   window.location = ""
  }
})



fetch(`${apiUrl}/raffles/payments/${result}`, {
   method: 'GET',
   credentials: 'include', 
   headers: {
          'Content-Type': 'application/json' 
   }
 })
.then(response => {
   
   
response.json().then(data=>{
  this.setState({data})
  this.setState({Loader:false})
   })


})


  }
   render() {
   if(this.state.Loader == true){

    return (
 <>
     <main>
 <div class="sc-179422ed-0 keEfHG ckmail">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       
       </div>
</main>
<ToastContainer/>
 </>
    );

}

return(<>

    <div class="sc-de9764ff-0 lgHNTc">
       <div class="sc-de9764ff-1 hLeoNV">
          <div class="sc-de9764ff-2 cvwfOn">
          EuRifei
          </div>
          <span class="sc-20b9c76f-0 dgQNDx">
             <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-timer ">
                <line x1="10" x2="14" y1="2" y2="2"></line>
                <line x1="12" x2="15" y1="14" y2="11"></line>
                <circle cx="12" cy="14" r="8"></circle>
             </svg>
             <span>{this.converterMinutosParaHoras(this.state.data.time_to_expire_orders)}</span>
          </span>
       </div>
    </div>
    <main>
    <div class="sc-772e6754-0 hWJumh">
   
   {this.state.data.status == "in_process" && <>
   <div class="sc-772e6754-2 bzDZrk">
      
      <div class="sc-772e6754-6 gfreh">
         <div class="sc-772e6754-4 faBwbl">
            <div class="sc-772e6754-5 iJWUbY">
               <div class="sc-772e6754-7 ilynyl">
                  <div class="sc-772e6754-8 iUbaab"><span></span><span></span><span></span></div>
               </div>
               <h3>Quase lá!</h3>
               <p>Efetue o pagamento do seu pedido para garantir a sua participação</p>
            </div>
         </div>
      </div>
     
      <div class="sc-772e6754-3 kOTMxY">
         <div class="sc-654a59d9-5 iPHlRc">
            <div class="sc-654a59d9-6 hlPReY">
               <div class="sc-538d1dac-6 hLDlwv">
                  <div class="sc-538d1dac-7 cuMLcU">
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z"></path>
                        <line x1="12" x2="12" y1="8" y2="12"></line>
                        <line x1="12" x2="12.01" y1="16" y2="16"></line>
                     </svg>
                  </div>
                
                  <h2>Importante</h2>
               </div>
               <p>Após efetuar o pagamento, envie o comprovante de pagamento no <b>WhatsApp</b> para o(a) administrador(a) da campanha aprovar o seu pedido.</p>
              
            </div>
         </div>
         <div class="sc-654a59d9-5 iPHlRc">
            <div class="sc-654a59d9-6 hlPReY">
               <div class="sc-538d1dac-6 hLDlwv">
                  <div class="sc-538d1dac-7 cuMLcU">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-copy ">
                        <rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
                        <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                     </svg>
                  </div>
                  <h2>Copie o código para pagar</h2>
               </div>
               <div class="sc-4a58ec21-0 hutWnh">
                  <div class="sc-4a58ec21-1 xyBfw">
                     <div>
                        <div class="sc-4a58ec21-2 iQQBbk"><span>1</span></div>
                     </div>
                     <p>Abra o aplicativo do seu banco.</p>
                  </div>
                  <div class="sc-4a58ec21-1 xyBfw">
                     <div>
                        <div class="sc-4a58ec21-2 iQQBbk"><span>2</span></div>
                     </div>
                     <p>Escolha pagar via Pix, e clique em código copia e cola.</p>
                  </div>
                  <div class="sc-4a58ec21-1 xyBfw">
                     <div>
                        <div class="sc-4a58ec21-2 iQQBbk"><span>3</span></div>
                     </div>
                     <p>Cole o código abaixo:</p>
                  </div>
               </div>
               <div class="sc-4a58ec21-3 debPUF">
               <img src={this.state.data.pay.img} alt="QR Code do Pix" width="212" height="212"/>

               </div>
               <div  class="sc-fcdb2543-0 kXCZMn">
                  <input type="text" readonly="" class="sc-fcdb2543-1 liWEHA" value={this.state.data.pay.keyCode}/>
                  <div class="sc-fcdb2543-2 gmQgyP"></div>
                  <button type="button" aria-label="Copiar" class="sc-fcdb2543-3 bMxLJK" onClick={()=>{
  
  copy(this.state.data.pay.keyCode)
      .then(() => {
         toast.success('Texto copiado para área de transferência', {
            position: "top-center",
            autoClose: 3000, // Fecha automaticamente após 3 segundos
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      })
      .catch(err => {
        console.error('Falha ao copiar: ', err);
      });
  
  
  
  
  
  
                  }}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-copy ">
                        <rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
                        <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                     </svg>
                     <span>Copiar</span>
                  </button>
               </div>
            </div>
            <hr/>
            <div class="sc-654a59d9-6 hlPReY">
               <div class="sc-538d1dac-6 hLDlwv">
                  <div class="sc-538d1dac-7 cuMLcU">
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-scan-line ">
                        <path d="M3 7V5a2 2 0 0 1 2-2h2"></path>
                        <path d="M17 3h2a2 2 0 0 1 2 2v2"></path>
                        <path d="M21 17v2a2 2 0 0 1-2 2h-2"></path>
                        <path d="M7 21H5a2 2 0 0 1-2-2v-2"></path>
                        <path d="M7 12h10"></path>
                     </svg>
                  </div>
                  <h2>Ou escaneie o QR code</h2>
               </div>
               <div class="sc-4a58ec21-0 hutWnh">
                  <div class="sc-4a58ec21-1 xyBfw">
                     <div>
                        <div class="sc-4a58ec21-2 iQQBbk"><span>1</span></div>
                     </div>
                     <p>Abra o aplicativo do seu banco.</p>
                  </div>
                  <div class="sc-4a58ec21-1 xyBfw">
                     <div>
                        <div class="sc-4a58ec21-2 iQQBbk"><span>2</span></div>
                     </div>
                     <p>Escolha pagar via Pix, e escaneie o código abaixo:</p>
                  </div>
               </div>
                     </div>
         </div>
         <div class="sc-654a59d9-5 iPHlRc">
            <div class="sc-654a59d9-6 hlPReY">
               <div class="sc-538d1dac-6 hLDlwv">
                  <div class="sc-538d1dac-7 cuMLcU">
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clipboard-list ">
                        <rect width="8" height="4" x="8" y="2" rx="1" ry="1"></rect>
                        <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                        <path d="M12 11h4"></path>
                        <path d="M12 16h4"></path>
                        <path d="M8 11h.01"></path>
                        <path d="M8 16h.01"></path>
                     </svg>
                  </div>
                  <h2>Detalhes do pedido</h2>
               </div>
               <div class="sc-772e6754-9 iUPhuv">
                  <div class="sc-654a59d9-16 dywSGM"><span><b>Comprador(a):</b> {this.state.data.user.name}</span></div>
                  <div class="sc-654a59d9-16 dywSGM"><span><b>E-mail:</b> {this.state.data.user.email}</span></div>
                  <div class="sc-654a59d9-16 dywSGM"><span><b>CPF/CNPJ:</b> {this.state.data.user.document}</span></div>
                  <div class="sc-654a59d9-16 dywSGM"><span><b>Telefone:</b> {this.state.data.user.phone}</span></div>
                  <div class="sc-654a59d9-16 dywSGM"><span><b>Status:</b> {this.state.data.status}</span></div>
                  <div class="sc-654a59d9-16 dywSGM"><span><b>Data:</b> {this._Data(this.state.data.created_at)}</span></div>
                  <div class="sc-654a59d9-16 dywSGM">
                     <span ><b>Bilhetes:</b></span>
                     <div class="sc-654a59d9-17 iFliWP">
                   {this.state.data.numbers != false ? <>
                     {this.state.data.numbers.map((item,index)=><>
                     <div class="sc-654a59d9-18 jtWZRf">{item}</div>
                    </>)}
                   </> : "Disponíveis somente após realizar o pagamento"}
                  

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   </>}
  
   {this.state.data.status == "approved" && <>
   <div class="sc-772e6754-2 bzDZrk">
      
      <div class="sc-772e6754-6 gfreh">
         <div class="sc-772e6754-4 gOqqyJ">
            <div class="sc-772e6754-5 iJWUbY">
            <div class="sc-77b8b83a-7 kNegAp">
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check ">
      <path d="M20 6 9 17l-5-5"></path>
   </svg>
</div>

               <h3>Pagamento aprovado</h3>
               <p>Você já está participando, agora é só torcer!</p>
            </div>
         </div>
      </div>
      <div class="go-rifa">
         <Link to={window.location.pathname.split("/checkout/")[0]}>
         <button>Voltar para rifa</button>
         </Link>
       
         
      </div>
      <div class="sc-772e6754-3 kOTMxY">
        
      
         <div class="sc-654a59d9-5 iPHlRc">
            <div class="sc-654a59d9-6 hlPReY">
               <div class="sc-538d1dac-6 hLDlwv">
                  <div class="sc-538d1dac-7 cuMLcU">
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clipboard-list ">
                        <rect width="8" height="4" x="8" y="2" rx="1" ry="1"></rect>
                        <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                        <path d="M12 11h4"></path>
                        <path d="M12 16h4"></path>
                        <path d="M8 11h.01"></path>
                        <path d="M8 16h.01"></path>
                     </svg>
                  </div>
                  <h2>Detalhes do pedido</h2>
               </div>
               <div class="sc-772e6754-9 iUPhuv">
                  <div class="sc-654a59d9-16 dywSGM"><span><b>Comprador(a):</b> {this.state.data.user.name}</span></div>
                  <div class="sc-654a59d9-16 dywSGM"><span><b>E-mail:</b> {this.state.data.user.email}</span></div>
                  <div class="sc-654a59d9-16 dywSGM"><span><b>CPF/CNPJ:</b> {this.state.data.user.document}</span></div>
                  <div class="sc-654a59d9-16 dywSGM"><span><b>Telefone:</b> {this.state.data.user.phone}</span></div>
                  <div class="sc-654a59d9-16 dywSGM"><span><b>Status:</b> {this.state.data.status}</span></div>
                  <div class="sc-654a59d9-16 dywSGM"><span><b>Data:</b> {this._Data(this.state.data.created_at)}</span></div>
                  <div class="sc-654a59d9-16 dywSGM">
                     <span ><b>Bilhetes:</b></span>
                     <div class="sc-654a59d9-17 iFliWP">
                   {this.state.data.numbers != false ? <>
                     {this.state.data.numbers.map((item,index)=><>
                     <div class="sc-654a59d9-18 jtWZRf">{item}</div>
                    </>)}
                   </> : "Disponíveis somente após realizar o pagamento"}
                  

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   </>}

   <div class="sc-f072f39c-0 hMpREF">
      <div class="sc-f072f39c-1 esSgF">
         <div class="sc-f072f39c-2 elurGv">
            <div style={{width:60,height:60}}>
               <div class="sc-f072f39c-4 evaxBY">
{this.state.data.imagem.length > 0 ? <>
   <img src={`${apiUrl}/static/${this.state.data.imagem[0].name}`} alt="" loading="lazy" decoding="async"/>       
</> : <>
<img src={`https://rifei.imgix.net/storage/images/no-image.jpg?w=1080&fit=max&q=70&auto=format,compress`} alt="" loading="lazy" decoding="async"/>       
</>}
 </div>          
            </div>
            <div class="sc-f072f39c-3 kNtCOb">
               <p>{this.state.data.title}</p>
            </div>
         </div>
         <div class="sc-f072f39c-5 dhBgMh">
            <div class="sc-f072f39c-6 cQOqid">
               <div class="sc-f072f39c-7 gvhgET">
                  <div class="sc-f072f39c-8 jKDTeY">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle ">
                        <circle cx="12" cy="12" r="10"></circle>
                     </svg>
                  </div>
                  <span>Subtotal</span>
               </div>
               <div class="sc-f072f39c-9 jTcwie"><span>{new Number(this.state.data.transaction_amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></div>
            </div>
            <div class="sc-f072f39c-6 cQOqid">
               <div class="sc-f072f39c-7 gvhgET total">
                  <div class="sc-f072f39c-8 jKDTeY">
                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign ">
                        <line x1="12" x2="12" y1="2" y2="22"></line>
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                     </svg>
                  </div>
                  <span>Total</span>
               </div>
               <div class="sc-f072f39c-9 jTcwie">
                  <h4>{new Number(this.state.data.transaction_amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h4>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

    </main>
    <ToastContainer/>
    </>)




  }
}

export default Finish;