import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import "./rfa.css"
import logo from "../../../src/images/logo.png"
import { apiUrl } from '../Config';
import { Link } from 'react-router-dom';
import Modal from '../../Comps/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
class Rifinhas extends React.Component {
   constructor(props) {
      super(props);
     
      this.state = {
     Loader:true,
     data:{},
     notFound:false,
     selectedButtons: [],
     manualSlc:10,
      }  
   }
   componentDidMount(){
      document.title = "Carregando..."
      var url = window.location.href;
      var pastaAtual = url.substring(url.lastIndexOf("/") + 1);
      fetch(`${apiUrl}/rifinhas/list/`+pastaAtual, {
         method: 'GET',
         credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
         headers: {
           'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
         }
       })
       .then(response => {
if(response.status == 200){
response.json().then(data=>{
 if(data.status){
   document.title  = `${data.rifinhas_title} | EuRifei`
 }else{
   document.title  = `Página não encontrada | EuRifei`
 }
   this.setState({data,Loader:false,status:data.status})
})
}

       })
   }
   handleClick = (index) => {
      this.setState((prevState) => {
        if (prevState.selectedButtons.includes(index)) {
          return {
            selectedButtons: prevState.selectedButtons.filter(
              (buttonIndex) => buttonIndex !== index
            ),
          };
        } else {
          return {
            selectedButtons: [...prevState.selectedButtons, index],
          };
        }
      });
    };
  render() {
  
   if(this.state.Loader){
      return(<>
         <div class="sc-179422ed-0"  style={{ display: 'flex', alignItems: 'center', height: '100vh',justifyContent:'center' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       </div>
      </>)
   }
    return (
 <>
 
 {this.state.data.status ? <>
   <header class="sc-d15d4f87-0 fMFqRX header-nav-fixed">
   <nav aria-label="Menu principal" class="sc-d15d4f87-1 gCnxGZ">
      <div class="sc-d15d4f87-2 deffc header-mobile-menu">
         <header></header>
         <ul class="navigation">
            <li class="navigation-item">
               <button type="button" aria-label="Ver meus bilhetes">
                  <div>
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart ">
                        <circle cx="8" cy="21" r="1"></circle>
                        <circle cx="19" cy="21" r="1"></circle>
                        <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
                     </svg>
                  </div>
                  <span>Ver meus bilhetes</span>
               </button>
            </li>
            <li class="navigation-item">
               <a href="/campanhas/fabriciozago2014160b9" rel="noopener noreferrer" aria-label="Campanhas">
                  <div >
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket ">
                        <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path>
                        <path d="M13 5v2"></path>
                        <path d="M13 17v2"></path>
                        <path d="M13 11v2"></path>
                     </svg>
                  </div>
                  <span>Campanhas</span>
               </a>
            </li>
            <li class="navigation-item">
               <a href="https://wa.me/5561981010404" target="_blank" rel="noopener noreferrer" aria-label="Contato">
                  <div>
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
                     </svg>
                  </div>
                  <span>Contato</span>
               </a>
            </li>
         </ul>
         <div class="sc-d15d4f87-3 esZBWS">
         <a href={`whatsapp://send?text=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no WhatsApp" class="sc-84a33c3d-1 kzXqZS">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 24 24">
               <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
            </svg>
         </a>
         <a href={`https://facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Facebook" class="sc-84a33c3d-1 dXNPhl">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook ">
               <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
            </svg>
         </a>
         <a href={`https://t.me/share/url?url=${window.location.href}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Telegram" class="sc-84a33c3d-1 yDVKT">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
               <path d="m5.9 13.5l-5.4-1.7c0 0-0.7-0.3-0.5-0.9 0.1-0.1 0.2-0.2 0.4-0.4 1.2-0.8 22.3-8.4 22.3-8.4 0 0 0.6-0.2 0.9-0.1q0.1 0.1 0.1 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0 0.1 0 0.1 0 0.2 0 0 0 0.1 0 0 0 0.1c0 0.1 0 0.3 0 0.5-0.2 2-4 17.5-4 17.5 0 0-0.2 0.9-1 0.9q-0.2 0-0.3 0-0.2 0-0.3-0.1-0.2-0.1-0.3-0.1-0.1-0.1-0.2-0.2c-1.7-1.4-7.2-5.2-8.5-6q0 0 0 0 0 0 0-0.1 0 0 0 0-0.1 0-0.1 0c0-0.1 0.1-0.2 0.1-0.2 0 0 9.7-8.7 10-9.6 0 0-0.1-0.1-0.2 0-0.6 0.2-11.8 7.2-13 8q-0.1 0-0.1 0 0 0-0.1 0 0 0 0 0-0.1 0-0.1 0z"></path>
            </svg>
         </a>
         <a href={`https://twitter.com/share?url=${window.location.href}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Twitter" class="sc-84a33c3d-1 daTCjo">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-twitter ">
               <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
            </svg>
         </a>
         </div>
      </div>
      <div class="sc-d15d4f87-5 cUWbSa header-container">
         <div class="sc-d15d4f87-6 gvOTnf">
            <div class="sc-d15d4f87-7 dBdBPq">
              EuRifei
            </div>
            <button type="button" aria-label="Abrir menu" aria-expanded="false" aria-haspopup="true" class="sc-d15d4f87-8 iVhvGR">
               <div class="sc-d15d4f87-9 cvqGmm">
                  <div class="sc-d15d4f87-10 dpdcxm header-hamburguer-line"></div>
                  <div class="sc-d15d4f87-10 dpdcxm header-hamburguer-line"></div>
               </div>
            </button>
         </div>
         <ul data-orientation="horizontal" dir="ltr" class="sc-d15d4f87-11 dAWJrA">
            <li class="sc-d15d4f87-12 gXNzsT">
               <a href="/campanhas/fabriciozago2014160b9" rel="noopener noreferrer" aria-label="Campanhas" class="sc-d15d4f87-13 bSdike">
                  <div >
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket ">
                        <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path>
                        <path d="M13 5v2"></path>
                        <path d="M13 17v2"></path>
                        <path d="M13 11v2"></path>
                     </svg>
                  </div>
                  Campanhas
               </a>
            </li>
            <li class="sc-d15d4f87-12 gXNzsT">
               <a href={`https://wa.me/${this.state.data.phone}`} target="_blank" rel="noopener noreferrer" aria-label="Contato" class="sc-d15d4f87-13 bSdike">
                  <div >
                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
                     </svg>
                  </div>
                  Contato
               </a>
            </li>
         </ul>
      </div>
   </nav>
</header>
<div class="sc-3e2ef0dc-1 cFlIDH">

<div class="sc-3e2ef0dc-3 iavOZJ">
   <div class="sc-3e2ef0dc-4 iFnwFU">
      <div class="sc-3e2ef0dc-6 gbakAV">
         <h1 class="sc-3e2ef0dc-11 eQrPvH slide-default">{this.state.data.rifinhas_title}</h1>
         <div class="sc-3e2ef0dc-12 gZwDsw">
            <p>Por apenas</p>
            <div class="sc-3e2ef0dc-13 dqoGrZ">{this.state.data.price}</div>
         </div>
      </div>
      <div class="sc-3e2ef0dc-6 gbakAV">
         <small>Prêmio(s):</small>
       
       {this.state.data.awards.map((item,index)=><>
         <div class="sc-3e2ef0dc-16 glvFQU">
            <div class="sc-3e2ef0dc-19 jJNLpF">
               <div class="sc-3e2ef0dc-18 epNifc">
                  <div>🥇</div>
                  <span class="award-title">
                     {index+1}º ganhador(a):
                  </span>
               </div>
               <p>{item.title}</p>
            </div>
         </div>
       </>)}
        
      </div>
   </div>
   <div class="sc-3e2ef0dc-9 fsGbld">
      <div class="sc-3e2ef0dc-6 gbakAV">
         <p class="sc-3e2ef0dc-15 eQowfz">Campanha principal:</p>
         <Link to={`/${this.state.data.campanha_slug}`} aria-label="Ir para a campanha principal" rel="noopener noreferrer" class="sc-3e2ef0dc-24 kNKoZM">
            <div>
               <div class="sc-3e2ef0dc-25 dWfZyg">
                  {this.state.data.imagem_principal.length > 0 ? <>
                     <img src={`${apiUrl}/static/${this.state.data.imagem_principal[0].name}`} alt={this.state.data.rifinhas_title} decoding="async" loading="lazy" />
             
                  </> : <>
                  <img src="https://rifei.imgix.net/storage/images/no-image.jpg?w=1080&amp;fit=max&amp;q=70&amp;auto=format,compress" alt="Demonstração" decoding="async" loading="lazy" />
             
                  </>}
                 </div>
            </div>
            <div class="sc-3e2ef0dc-26 kbeGHS">
               <h3>{this.state.data.campanha_title}</h3>
            </div>
         </Link>
      </div>
      <div class="sc-c4f18ec9-8 flewlM">
         <p class="sc-c4f18ec9-18 ffsVIp">Compartilhar:</p>
         <div class="sc-5122807c-0 fiBZHc">
            <a href="whatsapp://send?text=https://rifei.com.br/rifinha/demonstracao" target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no WhatsApp" title="WhatsApp" class="sc-5122807c-1 frStpn">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
               </svg>
            </a>
            <a href="https://facebook.com/sharer/sharer.php?u=https://rifei.com.br/rifinha/demonstracao" target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Facebook" title="Facebook" class="sc-5122807c-1 kZDJgE">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook ">
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
               </svg>
            </a>
            <a href="https://t.me/share/url?url=https://rifei.com.br/rifinha/demonstracao&amp;text=Rifinha%20de%20Demonstra%C3%A7%C3%A3o" target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no Telegram" title="Telegram" class="sc-5122807c-1 cEkuuC">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                  <path d="m5.9 13.5l-5.4-1.7c0 0-0.7-0.3-0.5-0.9 0.1-0.1 0.2-0.2 0.4-0.4 1.2-0.8 22.3-8.4 22.3-8.4 0 0 0.6-0.2 0.9-0.1q0.1 0.1 0.1 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0 0.1 0 0.1 0 0.2 0 0 0 0.1 0 0 0 0.1c0 0.1 0 0.3 0 0.5-0.2 2-4 17.5-4 17.5 0 0-0.2 0.9-1 0.9q-0.2 0-0.3 0-0.2 0-0.3-0.1-0.2-0.1-0.3-0.1-0.1-0.1-0.2-0.2c-1.7-1.4-7.2-5.2-8.5-6q0 0 0 0 0 0 0-0.1 0 0 0 0-0.1 0-0.1 0c0-0.1 0.1-0.2 0.1-0.2 0 0 9.7-8.7 10-9.6 0 0-0.1-0.1-0.2 0-0.6 0.2-11.8 7.2-13 8q-0.1 0-0.1 0 0 0-0.1 0 0 0 0 0-0.1 0-0.1 0z"></path>
               </svg>
            </a>
            <a href="https://twitter.com/share?url=https://rifei.com.br/rifinha/demonstracao&amp;text=Rifinha%20de%20Demonstra%C3%A7%C3%A3o" target="_blank" rel="noopener noreferrer" aria-label="Compartilhar no X (Twitter)" title="X (Twitter)" class="sc-5122807c-1 dlVKxp">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 300 271">
                  <path xmlns="http://www.w3.org/2000/svg" d="m236 0h46l-101 115 118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123-113-148h94.9l65.5 86.6zm-16.1 244h25.5l-165-218h-27.4z"></path>
               </svg>
            </a>
         </div>
      </div>
   </div>
</div>

{this.state.data.show_numbers == 1 ? <>

<div class="sc-cc128809-2 hjOGch">
<div class="sc-e1a39f58-0 hnQXdV">
   <div class="sc-cc128809-13 fyFjux">
      <div>
         <div class="sc-cc128809-28 cxQIMN">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket ">
               <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path>
               <path d="M13 5v2"></path>
               <path d="M13 17v2"></path>
               <path d="M13 11v2"></path>
            </svg>
         </div>
      </div>
      <h1 class="sc-cc128809-14 fCiQfM">Bilhetes</h1>
   </div>
</div>
<p class="sc-e1a39f58-1 jbUbQM">Selecione abaixo quais bilhetes deseja ver</p>
</div>



<div class="sc-cc128809-2 hjOGch">
<div class="sc-e1a39f58-4 eleDfn">
   <button type="button" class="sc-e1a39f58-5 VTamF all" aria-label="Todos"><span>Todos</span><span class="sc-e1a39f58-6 gvLTZy all">{this.state.data.numbers}</span></button>
   <button type="button" class="sc-e1a39f58-5 VTamF available" aria-label="Disponíveis"><span>Disponíveis</span><span class="sc-e1a39f58-6 gvLTZy available">0</span></button>
   <button type="button" class="sc-e1a39f58-5 VTamF approved" aria-label="Pagos"><span>Pagos</span><span class="sc-e1a39f58-6 gvLTZy approved">0</span></button>
   <button type="button" class="sc-e1a39f58-5 VTamF in_process" aria-label="Reservados"><span>Reservados</span><span class="sc-e1a39f58-6 gvLTZy in_process">0</span></button>
   <button type="button" class="sc-e1a39f58-5 VTamF search-numbers" aria-label="Ver meus bilhetes" onClick={()=>{
 


 Modal.create({ titulo: 'Ver meus bilhetes' ,html:<>
<fieldset class="sc-56eec735-1 bSrfIZ">
<div class="sc-2d577abe-0 jYSPyC"><span>Telefone com DDD</span></div>
<div class="sc-56eec735-2 bBSNUk"><input   autocomplete="off" class="sc-56eec735-0 bNrsML2" onChange={(e)=>{
   this.setState({
      UserPhone:e.target.value
   })
}} value={this.state.UserPhone}/></div>
<span class="sc-babfcf74-0 err-pg"></span>
</fieldset>

<div class="sc-8abcf529-1 kIrWpL">
<button type="submit" class="sc-688eee80-0 dSLnGJ" aria-label="Buscar" onClick={()=>{
fetch(`${apiUrl}/rifinhas/${this.state.data.id}/orders/search-by-phone/${this.state.UserPhone}`, {
method: 'GET',
credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
headers: {
  'Authorization': 'Bearer '+Cookies.get('auth_user'), // Se necessário, adicione um cabeçalho de autorização
  'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
}
})
.then(response => {
if (response.status == 404) {

   this.setState({err:true})

}


return response.json();

}).then(result => {





})
.catch(error => {

});
}}>
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search ">
      <circle cx="11" cy="11" r="8"></circle>
      <path d="m21 21-4.3-4.3"></path>
   </svg>
   Buscar
</button>
</div>


 </> });

   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart ">
         <circle cx="8" cy="21" r="1"></circle>
         <circle cx="19" cy="21" r="1"></circle>
         <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
      </svg>
      <span> Ver meus bilhetes</span>
   </button>
</div>
</div>
<div id="numbers" class="sc-e1a39f58-7 kCqnlk">


{this.state.data.list.map((item,index)=><>

{item.status == "available" ? <>
<button className={this.state.selectedButtons.includes(index) ? 'sc-e1a39f58-8 gJdDMo ticket selected' : 'sc-e1a39f58-8 gJdDMo ticket'}  onClick={()=>{
   this.handleClick(index)
}}>{item.number}</button>

</> : <>
<button  className={`sc-e1a39f58-8 gJdDMo ticket ${item.status}`} onClick={()=>{
   Modal.create({titulo:`Bilhete #${item.number}`,html:<>
   <div class="sc-e1a39f58-17 cUeHHZ"><small>Participante:</small><p><b>{item.name}</b></p><small>Status:</small><p><b>{item.status == "approved" ? "Pago" : "Reservado"}</b></p></div>
   </>})
}}>{item.number}</button>
</>}

</>)}



</div>

{this.state.selectedButtons.length > 0 && <>
<div className="sc-e1a39f58-9 gYPbsO" style={{ transform: 'none' }}>
   <div className="sc-e1a39f58-10 gPeSQm">
     <div className="sc-e1a39f58-11 iQfOJV">
       <div className="sc-e1a39f58-13 bAHuwg">
         <div className="sc-e1a39f58-14 iTwJAS">
           <h2>
              {Number(this.state.selectedButtons.length * this.state.data.price).toLocaleString('pt-BR', {
 style: 'currency',
 currency: 'BRL',
})}
           </h2>
         </div>
       </div>
       <div className="sc-e1a39f58-16 hfCDto">
         <button type="button"  className="sc-688eee80-2 jVdzQX" onClick={()=>{
            this.setState({selectedButtons:[]})
         }}>
           Cancelar
         </button>
         <button
           type="button"
           id="checkout-button"
         
           aria-label="Participar"
           className={this.state.ptc == true ? "sc-688eee80-0 gzBlUv load" : "sc-688eee80-0 gzBlUv"}
           disabled={this.state.ptc == true ? true : false}
           onClick={()=>{
            this.setState({ptc:true})
            fetch(`${apiUrl}/rifinhas/${this.state.data.id}/cart/`, {
               method: 'POST',
               credentials: 'include',
               headers: {
                 'Content-Type': 'application/json',
               },
               body: JSON.stringify({
                numbers:this.state.selectedButtons.map(item => item + 1)
               })
             })
               .then(response => {
                 if (response.status === 403) {
                response.json().then(data=>{
                  
                  toast.error(data.errors[0].message, {
                     position: "top-center",
                     autoClose: 5000, // Fecha automaticamente após 3 segundos
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                   });

                })
                 }
                 if (response.status === 200) {
                   // Tratar resposta 200
                   return response.json();
                 }
               })
               .then(data => {
                  this.setState({ptc:false})
                if(data.status == 1){
                this.props.navigate(`rifinha/${data.slug}/checkout`)
                }
                 
               })
               .catch(error => {
                 // Lógica para lidar com o erro
                 console.error('Erro:', error);
               });
         }}>
        Reservar
         </button>
       </div>
     </div>
   </div>
 </div>
</>}

</> : <>


<div class="sc-cc128809-2 hjOGch">
<div class="sc-84f71e53-7 jXuczH">
<div class="sc-84f71e53-8 bSdbbZ">
   <p class="sc-84f71e53-2 eabebY">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-list ">
         <line x1="8" x2="21" y1="6" y2="6"></line>
         <line x1="8" x2="21" y1="12" y2="12"></line>
         <line x1="8" x2="21" y1="18" y2="18"></line>
         <line x1="3" x2="3.01" y1="6" y2="6"></line>
         <line x1="3" x2="3.01" y1="12" y2="12"></line>
         <line x1="3" x2="3.01" y1="18" y2="18"></line>
      </svg>
      Selecione a quantidade de bilhetes
   </p>
   <div class="sc-84f71e53-9 fPyHdM">
   <button type="button" aria-label="Selecionar mais 20 bilhete(s)" class="sc-84f71e53-10 cAljXc" onClick={()=>{

     
var n = (this.state.manualSlc + 20)

if(n <= this.state.data.numbers){
this.setState({manualSlc:n})
}else{
this.setState({manualSlc:this.state.data.numbers})
}
 

}}><span>Selecionar</span><span class="sc-84f71e53-11 dPNhKc">+20</span></button>
   <button type="button" aria-label="Selecionar mais 40 bilhete(s)" class="sc-84f71e53-10 cAljXc most-popular"><span>Selecionar</span><span class="sc-84f71e53-11 dPNhKc" onClick={()=>{

     
var n = (this.state.manualSlc + 40)

if(n <= this.state.data.numbers){
this.setState({manualSlc:n})
}else{
this.setState({manualSlc:this.state.data.numbers})
}
 

}}>+40</span></button>
   <button type="button" aria-label="Selecionar mais 60 bilhete(s)" class="sc-84f71e53-10 cAljXc" onClick={()=>{

     
var n = (this.state.manualSlc + 60)

if(n <= this.state.data.numbers){
this.setState({manualSlc:n})
}else{
this.setState({manualSlc:this.state.data.numbers})
}
 

}}><span>Selecionar</span><span class="sc-84f71e53-11 dPNhKc">+60</span></button>
   <button type="button" aria-label="Selecionar mais 100 bilhete(s)" class="sc-84f71e53-10 cAljXc" onClick={()=>{

     
    var n = (this.state.manualSlc + 100)

if(n <= this.state.data.numbers){
   this.setState({manualSlc:n})
}else{
   this.setState({manualSlc:this.state.data.numbers})
}
      

   }}><span>Selecionar</span><span class="sc-84f71e53-11 dPNhKc">+100</span></button>
   </div>
   <div class="sc-84f71e53-12 hWmTaP">
      <div class="sc-84f71e53-13 kydLCH">
         <button type="button" aria-label="Desselecionar 1 bilhete" class="sc-84f71e53-14 caCTUG" onClick={()=>{
            if (this.state.manualSlc > 10) {
               this.setState(prevState => ({
                 manualSlc: prevState.manualSlc - 1
               }));
             }
         }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-minus ">
               <path d="M5 12h14"></path>
            </svg>
         </button>
         <input aria-label="Quantidade de bilhetes" readonly="" class="sc-84f71e53-15 fRjceb" value={this.state.manualSlc}/>
         <button type="button" aria-label="Selecionar mais 1 bilhete" class="sc-84f71e53-14 caCTUG" onClick={()=>{
            if (this.state.manualSlc < this.state.data.numbers) {
            this.setState(prevState => ({
               manualSlc: prevState.manualSlc + 1
             }));
            }
         }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus ">
               <path d="M5 12h14"></path>
               <path d="M12 5v14"></path>
            </svg>
         </button>
      </div>
   </div>
</div>
<hr/>
<div class="sc-84f71e53-8 bSdbbZ">
   <div class="sc-84f71e53-16 exzoGB"><button type="button" id="checkout-button" aria-label="Participar"  className={this.state.ptc == true ? "sc-84f71e53-17 khOvmf load" : "sc-84f71e53-17 khOvmf"}
           disabled={this.state.ptc == true ? true : false}
           onClick={()=>{
            this.setState({ptc:true})
            fetch(`${apiUrl}/rifinhas/${this.state.data.id}/cart/`, {
               method: 'POST',
               credentials: 'include',
               headers: {
                 'Content-Type': 'application/json',
               },
               body: JSON.stringify({
                numbers:this.state.manualSlc
               })
             })
               .then(response => {
                  if (response.status === 502) {
                     this.setState({ptc:false})
                  }
                 if (response.status === 403) {
                response.json().then(data=>{
                  
                  toast.error(data.errors[0].message, {
                     position: "top-center",
                     autoClose: 5000, // Fecha automaticamente após 3 segundos
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                   });

                })
                 }
                 if (response.status === 200) {
                   // Tratar resposta 200
                   return response.json();
                 }
               })
               .then(data => {
                  this.setState({ptc:false})
                if(data.status == 1){
                this.props.navigate(`/rifinha/${data.slug}/checkout`)
                }
                 
               })
               .catch(error => {
                  this.setState({ptc:false})
                 console.error('Erro:', error);
               });
         }}>Reservar ({Number(this.state.manualSlc * this.state.data.price).toLocaleString('pt-BR', {
 style: 'currency',
 currency: 'BRL',
})})</button></div>
</div>
</div>


</div>

</>}

</div>

 </> : <>
 <main class="sc-de8cbe2d-0 iypOTp">
   <div class="sc-de8cbe2d-1 hCroVH">
      <div class="sc-de8cbe2d-3 kXRTMm">
         <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="currentColor" viewBox="0 0 1024 1024">
            <defs>
               <linearGradient id="_element_656" gradientUnits="userSpaceOnUse" x1="512" y1="1024.079" x2="512" y2="-0.079">
                  <stop offset="0" stop-color="#fb8b00"></stop>
                  <stop offset="1" stop-color="#ffc47b"></stop>
               </linearGradient>
               <linearGradient id="_element_464" gradientUnits="userSpaceOnUse" x1="109.066" y1="348.433" x2="109.066" y2="146.828">
                  <stop offset="0" stop-color="#0070fb"></stop>
                  <stop offset="1" stop-color="#7acbff"></stop>
               </linearGradient>
            </defs>
            <path fill="url(#_element_656)" d="M379.48 17.45C652.62 -55.74 933.37 106.35 1006.55 379.48C1079.74 652.62 917.65 933.37 644.52 1006.55C371.38 1079.74 90.63 917.65 17.45 644.52C-55.74 371.38 106.35 90.63 379.48 17.45Z"></path>
            <path fill="none" stroke="#000000" stroke-linecap="round" stroke-width="57" d="M244.46 599.22C244.46 599.22 235.86 545.62 295.2 529.72C353.01 514.23 371.96 565.05 371.96 565.05"></path>
            <path fill="none" stroke="#000000" stroke-linecap="round" stroke-width="57" d="M659.81 487.92C659.81 487.92 651.21 434.32 710.55 418.42C768.36 402.93 787.31 453.76 787.31 453.76"></path>
            <path fill="#000000" d="M557.55 682C614.17 666.83 649.47 705.85 644.68 713.53C641.24 719.05 610.88 700.84 565.83 712.91C524.32 724.04 505.89 756.33 498.83 752.61C490.38 748.16 500.71 697.23 557.55 682Z"></path>
            <path fill="url(#_element_464)" d="M99.54 156.59C158.71 190.76 249.14 310.84 126.3 343.75C2.57 376.91 22.48 225.67 56.42 166.88C70.38 142.7 73.95 141.82 99.54 156.59Z"></path>
         </svg>
      </div>
      <div class="sc-de8cbe2d-2 bAAQGt">
         <h1 class="sc-de8cbe2d-4 hZNJuz">Esta página não existe</h1>
         <p class="sc-de8cbe2d-5 kPXVax">Desculpe, a página que você está procurando não existe ou foi movida.</p>
      </div>
      <div class="sc-de8cbe2d-6 BJfFs">
    <Link to="/" class="sc-688eee80-0 ivGZii">Retornar ao início</Link></div>
   </div>
</main>
 </>}
 
<div id="modal-root"></div>
<ToastContainer/>
 </>
    );
  }
}

export default Rifinhas;