import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom"
//import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faDollar,
   faBoxOpen,
   faChartBar,
   faStore,
   faCheckCircle
    
 } from '@fortawesome/free-solid-svg-icons'
import Menu from './Menu';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Footer from './Footer';
import Section5 from './Section5';
import Section6 from './Section6';
import Section7 from './Section7';
import Section8 from './Section8';
export default function Inicio(props) {

  document.title = "Rifa Online | Crie sua campanha em poucos minutos"

return (<>
<Menu/>
<main class="sc-2c403d08-0 gwnKqC">
<Section1/>

<Section3/>

<Section5/>

<Section7/>

<Footer/>
</main>
</>
);
}
