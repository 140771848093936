import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "./style.css"

class Modal extends Component {
  constructor(props) {
    super(props);
    this.elRef = document.createElement('div');
    this.modalRoot = document.getElementById('modal-root');
  }

  componentDidMount() {
    console.log(this.props)
    this.modalRoot.appendChild(this.elRef);
    document.addEventListener('click', this.handleOutsideClick);
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.elRef);
    document.removeEventListener('click', this.handleOutsideClick);
  }

  destroyModal = () => {
    ReactDOM.unmountComponentAtNode(this.elRef);
    this.modalRoot.removeChild(this.elRef);
    this.modalRoot.innerHTML = '';
  };

  handleOutsideClick = (event) => {
    if(event.target.classList.contains('fyGHVY')){
        document.removeEventListener('click', this.handleOutsideClick);
        this.destroyModal()
    }
   
  };
  render() {
    return ReactDOM.createPortal(
      <div>
        
        <div className="sc-912e3955-0 fyGHVY">
          <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" className="sc-b992e925-1 kVYZTt">
            <div className="sc-b992e925-4 eCuIZO">
              <h1 className="sc-b992e925-2 iEZsiF">{this.props.titulo}</h1>
              <button type="button" className="sc-b992e925-3 hoeLrA" onClick={()=>{
                   document.removeEventListener('click', this.handleOutsideClick);
                   this.destroyModal()
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x ">
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div className="sc-b992e925-5 gmRYUp">
{this.props.html != undefined ? <>{this.props.html}</> : <>
{this.props.titulo === 'teste' && <p>Conteúdo personalizado para o título 'teste'</p>}
</>}
            
            </div>
          

          </div>
        </div>
      </div>,
      this.elRef
    );
  }
}

Modal.v2 = (e) =>{
    console.log(e)
}

Modal.close = () =>{
    const modalRoot = document.getElementById('modal-root');
    modalRoot.innerHTML = ''
}
Modal.create = ({ titulo,html }) => {
  const modalRoot = document.getElementById('modal-root');
  const elRef = document.createElement('div');
  modalRoot.appendChild(elRef);

  ReactDOM.render(<>
  <div style={{ opacity: 1 }} className="sc-912e3955-1 guWPuq" onClick={()=>{
    console.log('aaa')
  }}></div><Modal titulo={titulo} html={html} /></>, elRef);

  return {
    destroy: () => {
      modalRoot.removeChild(elRef);
    },
  };
};

export default Modal;
