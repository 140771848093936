import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import "./style.css"
import logo from "../../../src/images/logo.png"
import { Link } from 'react-router-dom';
import Footer from '../Inicio/Footer';
class Precos extends React.Component {
   

componentDidMount(){
   document.title = "Preços"
}


  render() {
    return (
 <>
 

 <header class="sc-e6341458-6 goCdep">
   <nav aria-label="Menu principal" class="sc-e6341458-7 exeFlu">
      <div class="sc-e6341458-1 bYjJsm header-mobile-menu">
         <ul class="header-navigation">
            <li class="header-navigation-item primary">
               <Link to="https://app.eurifei.com/" aria-label="Login">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-in ">
                        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                        <polyline points="10 17 15 12 10 7"></polyline>
                        <line x1="15" x2="3" y1="12" y2="12"></line>
                     </svg>
                  </div>
                  Login
               </Link>
            </li>
            <li class="header-navigation-item primary">
               <Link to="https://app.eurifei.com/sign-up" aria-label="Criar conta">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-plus ">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <line x1="19" x2="19" y1="8" y2="14"></line>
                        <line x1="22" x2="16" y1="11" y2="11"></line>
                     </svg>
                  </div>
                  Criar conta
               </Link>
            </li>
            <li class="header-navigation-item">
               <Link aria-label="Preços" to="/precos">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign ">
                        <line x1="12" x2="12" y1="2" y2="22"></line>
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                     </svg>
                  </div>
                  Preços
               </Link>
            </li>
            <li class="header-navigation-item">
               <Link aria-label="Sobre nós" to="/perguntas-frequente">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-help-circle ">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <path d="M12 17h.01"></path>
                     </svg>
                  </div>
                  Perguntas frequentes
               </Link>
            </li>
            <li class="header-navigation-item">
               <Link aria-label="Central de ajuda" to="/ajuda">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-life-buoy ">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="m4.93 4.93 4.24 4.24"></path>
                        <path d="m14.83 9.17 4.24-4.24"></path>
                        <path d="m14.83 14.83 4.24 4.24"></path>
                        <path d="m9.17 14.83-4.24 4.24"></path>
                        <circle cx="12" cy="12" r="4"></circle>
                     </svg>
                  </div>
                  Central de ajuda
               </Link>
            </li>
         </ul>
      </div>
      <div class="sc-e6341458-0 lnAbnn header-container">
         <div class="sc-e6341458-11 hUolII">
            <Link aria-label="Página inicial do Rifei" to="/" class="sc-e6341458-8 gxWuHA">
            EuRifei
            </Link>
            <button type="button" aria-label="Abrir menu" aria-expanded="false" aria-haspopup="true" class="sc-e6341458-3 jWdlOA">
               <div class="sc-e6341458-4 cOGBuu">
                  <div class="sc-e6341458-5 hFrINQ header-menu-line"></div>
                  <div class="sc-e6341458-5 hFrINQ header-menu-line"></div>
               </div>
            </button>
         </div>
         <div class="sc-e6341458-9 iga-dTb">
         <Link class="sc-e6341458-10 hOJEzN active" aria-label="Preços" to="/precos">Preços</Link>
         <Link class="sc-e6341458-10 hOJEzN" aria-label="Sobre nós" to="/perguntas-frequente">Perguntas frequentes</Link>
         <Link aria-label="Ajuda" to="/ajuda" class="sc-e6341458-10 hOJEzN">Ajuda</Link>
         </div>
         <div class="sc-e6341458-11 hUolII">
        <Link to="https://app.eurifei.com/" class="sc-688eee80-2 eJNlyb header-sign-in" aria-label="Login">Login</Link>
        <Link to="https://app.eurifei.com/sign-up" class="sc-688eee80-0 dDccFD header-sign-up" aria-label="Criar conta">Criar conta</Link></div>
      </div>
   </nav>
</header>

<main class="sc-671ff67c-0 gwJlDq">

<section class="sc-671ff67c-1 jArwSM">
   <div class="sc-671ff67c-2 iTAnTg">
      <div class="sc-671ff67c-4 eyopNs">
         <h1 class="sc-671ff67c-5 pdbPD">Encontre um plano para começar seu projeto</h1>
         <p class="sc-671ff67c-6 gwdnan">Independente do tamanho da sua campanha, você terá os melhores recursos do mercado.</p>
      </div>
   </div>
</section>

   
<section class="sc-671ff67c-7 guRtcT">
   <svg xmlns="http://www.w3.org/2000/svg" id="visual" viewBox="0 0 900 239" width="900" height="239" class="sc-671ff67c-9 iufxNu">
      <path d="m0 104l75-25.7c75-25.6 225-77 375-77.5 150-0.5 300 49.9 375 75l75 25.2v139h-75c-75 0-225 0-375 0-150 0-300 0-375 0h-75z" fill="#f8f8fa"></path>
   </svg>
   <div style={{top:500}} class="sc-671ff67c-8 koMZyH"></div>
   <div class="sc-671ff67c-3 eFzNQI">
      <div class="sc-671ff67c-10 hHCHZG">
         <div class="sc-671ff67c-11 fpKSnv">
            <div class="sc-671ff67c-12 ezAUOJ">
               <div class="sc-671ff67c-16 dheZic">
                  <div class="sc-671ff67c-17 kogsUD">
                     <h2>Econômico</h2>
                  </div>
                  <div class="sc-671ff67c-18 cVxYIT">
                     <h3>R$ 63,00</h3>
                     <span>por campanha</span>
                  </div>
               </div>
               <div class="sc-671ff67c-20 iONLWZ">
                  <Link to="https://app.eurifei.com/sign-up" rel="noopener noreferrer" aria-label="Começar agora" class="sc-688eee80-0 fzFtvG">
                     <span>Começar agora</span>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right ">
                        <path d="M5 12h14"></path>
                        <path d="m12 5 7 7-7 7"></path>
                     </svg>
                  </Link>
               </div>
               <hr/>
               <div class="sc-671ff67c-14 ZSsWy">
                  <ul>
                     <li>
                        <div>
                           <div class="sc-671ff67c-21 fvVCyn">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign ">
                                 <line x1="12" x2="12" y1="2" y2="22"></line>
                                 <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                              </svg>
                           </div>
                        </div>
                        <p>Até R$ 5,00 por bilhete</p>
                     </li>
                     <li>
                        <div>
                           <div class="sc-671ff67c-21 fvVCyn">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket ">
                                 <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path>
                                 <path d="M13 5v2"></path>
                                 <path d="M13 17v2"></path>
                                 <path d="M13 11v2"></path>
                              </svg>
                           </div>
                        </div>
                        <p>Até 1.000 bilhetes</p>
                     </li>
                     <li>
                        <div>
                           <div class="sc-671ff67c-21 fvVCyn">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-percent ">
                                 <line x1="19" x2="5" y1="5" y2="19"></line>
                                 <circle cx="6.5" cy="6.5" r="2.5"></circle>
                                 <circle cx="17.5" cy="17.5" r="2.5"></circle>
                              </svg>
                           </div>
                        </div>
                        <p>Promoções e cupons de desconto ilimitados</p>
                     </li>
                     <li>
                        <div>
                           <div class="sc-671ff67c-21 fvVCyn">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-users ">
                                 <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                                 <circle cx="9" cy="7" r="4"></circle>
                                 <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
                                 <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                              </svg>
                           </div>
                        </div>
                        <p>Afiliados ilimitados</p>
                     </li>
                     <li>
                        <div>
                           <div class="sc-671ff67c-21 fvVCyn">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-award ">
                                 <circle cx="12" cy="8" r="6"></circle>
                                 <path d="M15.477 12.89 17 22l-5-3-5 3 1.523-9.11"></path>
                              </svg>
                           </div>
                        </div>
                        <p>Prêmios ilimitados</p>
                     </li>
                     <li>
                        <div>
                           <div class="sc-671ff67c-21 fvVCyn">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-headphones ">
                                 <path d="M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 18 0v7a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3"></path>
                              </svg>
                           </div>
                        </div>
                        <p>Suporte de segunda a sábado via WhatsApp</p>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="sc-671ff67c-12 ezAUOJ">
               <div class="sc-671ff67c-13 iSRhMe">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-star ">
                     <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <span>Mais popular</span>
               </div>
               <div class="sc-671ff67c-16 dheZic">
                  <div class="sc-671ff67c-17 kogsUD">
                     <h2>Padrão</h2>
                  </div>
                  <div class="sc-671ff67c-18 cVxYIT">
                     <h3>R$ 130,00</h3>
                     <span>por campanha</span>
                  </div>
               </div>
               <div class="sc-671ff67c-20 iONLWZ">
                  <Link to="https://app.eurifei.com/sign-up" rel="noopener noreferrer" aria-label="Começar agora" class="sc-688eee80-0 kmuOAX">
                     <span>Começar agora</span>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right ">
                        <path d="M5 12h14"></path>
                        <path d="m12 5 7 7-7 7"></path>
                     </svg>
                  </Link>
               </div>
               <hr/>
               <div class="sc-671ff67c-14 ZSsWy">
                  <span class="sc-671ff67c-15 hGOrXR">Tudo no plano "Econômico", mais</span>
                  <ul>
                     <li>
                        <div>
                           <div class="sc-671ff67c-21 fvVCyn">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign ">
                                 <line x1="12" x2="12" y1="2" y2="22"></line>
                                 <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                              </svg>
                           </div>
                        </div>
                        <p>Valor ilimitado por bilhete</p>
                     </li>
                     <li>
                        <div>
                           <div class="sc-671ff67c-21 fvVCyn">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket ">
                                 <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path>
                                 <path d="M13 5v2"></path>
                                 <path d="M13 17v2"></path>
                                 <path d="M13 11v2"></path>
                              </svg>
                           </div>
                        </div>
                        <p>Até 100.000 bilhetes</p>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="sc-671ff67c-12 ezAUOJ">
               <div class="sc-671ff67c-16 dheZic">
                  <div class="sc-671ff67c-17 kogsUD">
                     <h2>Premium</h2>
                  </div>
                  <div class="sc-671ff67c-18 cVxYIT">
                     <small class="sc-671ff67c-19 glTQLA">De R$ 697,90 por</small>
                     <h3>R$ 453,00</h3>
                     <span>por campanha</span>
                  </div>
               </div>
               <div class="sc-671ff67c-20 iONLWZ">
                  <Link to="https://app.eurifei.com/sign-up" rel="noopener noreferrer" aria-label="Começar agora" class="sc-688eee80-0 fzFtvG">
                     <span>Começar agora</span>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right ">
                        <path d="M5 12h14"></path>
                        <path d="m12 5 7 7-7 7"></path>
                     </svg>
                  </Link>
               </div>
               <hr/>
               <div class="sc-671ff67c-14 ZSsWy">
                  <span class="sc-671ff67c-15 hGOrXR">Tudo no plano "Padrão", mais</span>
                  <ul>
                     <li>
                        <div>
                           <div class="sc-671ff67c-21 fvVCyn">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket ">
                                 <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path>
                                 <path d="M13 5v2"></path>
                                 <path d="M13 17v2"></path>
                                 <path d="M13 11v2"></path>
                              </svg>
                           </div>
                        </div>
                        <p>Até 1.000.000 de bilhetes</p>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
   <hr class="sc-671ff67c-24 gvAlgP"/>
   <div class="sc-671ff67c-3 eFzNQI">
      <div class="sc-671ff67c-10 hHCHZG">
         <ul class="sc-671ff67c-22 jZZteO">
            <li>
               <div>
                  <div class="sc-671ff67c-23 fPRMLg"><span>!</span></div>
               </div>
               <p>A sua campanha ficará ativa até o sorteio da mesma ser realizado ou todos os bilhetes forem comprados.</p>
            </li>
         </ul>
      </div>
   </div>
</section>



</main>

<Footer/>



 </>
    );
  }
}

export default Precos;