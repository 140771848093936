import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
class Section3 extends React.Component {
  render() {
    return (
 <>
 
 <section class="sc-2c403d08-16 ibmVzO">
   <div class="sc-2c403d08-8 klLsrI">
      <div class="info">
      <h3>Como funciona?</h3>
      <p>Confira o nosso vídeo mostrando passo a passo de como funciona a nossa plataforma:
</p>
      </div>

      <div class="sc-2c403d08-9 YDbbQ">
         <div class="sc-2c403d08-10 hPDazC">
        
         
            <iframe width="560" height="315" src="https://www.youtube.com/embed/WihGHK5FDX8?si=GbZ6kbDH3J6kwJfe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>        </div>
      </div>
   </div>
</section>



 </>
    );
  }
}

export default Section3;