import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import logo from "../../../images/logo.png"
import { Link } from 'react-router-dom';



class Footer extends React.Component {
   componentDidUpdate(){
      console.log('teste')
   }
  render() {
    return (
 <>
 
 <footer class="sc-64a453c2-0 fumfAL">
   <div class="sc-64a453c2-1 gUsOcN">
      <div class="sc-64a453c2-2 ytogt">
         <div class="sc-64a453c2-3 bZFNeE">
            <div class="logo-txt">
               <a aria-label="Rifei" href="/?utm_source=footer&amp;utm_medium=logo&amp;utm_campaign=site">
                EuRifei
               </a>
               <p>Plataforma para você criar e gerenciar suas campanhas com segurança e praticidade</p>
            </div>
            <div class="sc-64a453c2-4 djplug">
            
            </div>
         </div>
         
         <div class="sc-64a453c2-2 ytogt last-footer-content">
         <div>
            <span>
               Copyright © 2021 - 2024 EuRifei
            </span>
            <span>Todos os direitos reservados</span>
         </div>
         <div>
            <button type="button" aria-label="Voltar ao topo da página" class="sc-64a453c2-6 cPlCeG secondary-button" onClick={()=>{
               window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-up ">
                  <path d="m5 12 7-7 7 7"></path>
                  <path d="M12 19V5"></path>
               </svg>
               Voltar ao topo
            </button>
         </div>
      </div>

      </div>

<div class="sc-64a453c2-7 dmIaEy">
            <div>
               <h3>Páginas</h3>
               <Link aria-label="Início" to="/">Início</Link>
               <Link aria-label="Preço" onClick={()=>{
                    window.scrollTo({ top: 0, behavior: 'smooth' });
               }} to="/precos">Preço</Link>
               <Link aria-label="Sobre nós" to="/sobre-nos">Sobre nós</Link>
               <Link aria-label="Termos de uso" to="/ajuda/outros/termos-de-uso">Termos de uso</Link>
               <Link aria-label="Política de Privacidade" to="/ajuda/outros/politica-de-privacidade">Política de Privacidade</Link>
            </div>
            <div>
               <h3>Ajuda</h3>
     
               <a aria-label="Dúvidas frequentes" href="https://wa.me/+5513991154046">Fale conosco</a>




            </div>
            <div>
               <h3>Contato</h3>
               <a href="https://wa.me/" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">WhatsApp</a><a href="mailto:contato@eurifei.com.br" target="_blank" rel="noopener noreferrer" aria-label="E-mail">contato@eurifei.com.br</a>
            </div>
         </div>


     
   </div>
</footer>




 </>
    );
  }
}

export default Footer;