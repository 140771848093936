import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import logo from "../../../images/logo.png"
class Menu extends React.Component {
   constructor(props) {
      super(props);
     
      this.state = {
         menu:false
      }
   }
  render() {
    return (
 <>
 
 <header className={this.state.menu == true ? "sc-e6341458-6 goCdep active" : "sc-e6341458-6 goCdep"}>
   <nav aria-label="Menu principal" class="sc-e6341458-7 exeFlu">
      <div class="sc-e6341458-1 bYjJsm header-mobile-menu">
         <ul class="header-navigation">
            <li class="header-navigation-item primary">
               <a href="https://app.eurifei.com/" aria-label="Login">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-in ">
                        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                        <polyline points="10 17 15 12 10 7"></polyline>
                        <line x1="15" x2="3" y1="12" y2="12"></line>
                     </svg>
                  </div>
                  Login
               </a>
            </li>
            <li class="header-navigation-item primary">
               <a href="https://app.eurifei.com/sign-up" aria-label="Criar conta">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-plus ">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <line x1="19" x2="19" y1="8" y2="14"></line>
                        <line x1="22" x2="16" y1="11" y2="11"></line>
                     </svg>
                  </div>
                  Criar conta
               </a>
            </li>
            <li class="header-navigation-item">
               <Link aria-label="Preços" to="/precos">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign ">
                        <line x1="12" x2="12" y1="2" y2="22"></line>
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                     </svg>
                  </div>
                  Preços
               </Link>
            </li>
            <li class="header-navigation-item">
               <Link aria-label="Sobre nós" to="/perguntas-frequente">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-help-circle ">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <path d="M12 17h.01"></path>
                     </svg>
                  </div>
                  Perguntas frequentes
               </Link>
            </li>
            <li class="header-navigation-item">
               <Link aria-label="Central de ajuda" to="https://wa.me/+5513991154046">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-life-buoy ">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="m4.93 4.93 4.24 4.24"></path>
                        <path d="m14.83 9.17 4.24-4.24"></path>
                        <path d="m14.83 14.83 4.24 4.24"></path>
                        <path d="m9.17 14.83-4.24 4.24"></path>
                        <circle cx="12" cy="12" r="4"></circle>
                     </svg>
                  </div>
                  Central de ajuda
               </Link>
            </li>
         </ul>
      </div>
      <div class="sc-e6341458-0 lnAbnn header-container">
         <div class="sc-e6341458-11 hUolII">
            <a aria-label="Página inicial do Rifei" to="/" class="sc-e6341458-8 gxWuHA">
           EuRifei
            </a>
            <button type="button" aria-label="Abrir menu" onClick={()=>{
               this.setState({menu:!this.state.menu})
            }} aria-expanded="false" aria-haspopup="true" class="sc-e6341458-3 jWdlOA">
               <div class="sc-e6341458-4 cOGBuu">
                  <div class="sc-e6341458-5 hFrINQ header-menu-line"></div>
                  <div class="sc-e6341458-5 hFrINQ header-menu-line"></div>
                 
               </div>
            </button>
         </div>
         <div class="sc-e6341458-9 iga-dTb">
         <Link class="sc-e6341458-10 hOJEzN" aria-label="Preços" to="/precos">Preços</Link>
         <Link class="sc-e6341458-10 hOJEzN" aria-label="Sobre nós" to="/perguntas-frequente">Perguntas frequentes</Link>
         <Link aria-label="Ajuda" to="https://wa.me/+5513991154046" class="sc-e6341458-10 hOJEzN">Ajuda</Link>
         </div>
         <div class="sc-e6341458-11 hUolII">
         <a href="https://app.eurifei.com/" class="sc-688eee80-2 eJNlyb header-sign-in" aria-label="Login">Login</a>
         <a href="https://app.eurifei.com/sign-up" class="sc-688eee80-0 dDccFD header-sign-up" aria-label="Criar conta">Criar conta</a></div>
      </div>
   </nav>
</header>


<div class="sc-e6341458-2 gSnkdf header-backdrop" onClick={()=>{
   this.setState({menu:false})
}}></div>
 </>
 
    );
  }
}

export default Menu;