import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
class Section1 extends React.Component {
  render() {
    return (
 <>
 

 <section class="sc-2c403d08-1 jgBKKC">
   <div class="sc-2c403d08-2 flgAwv">
      <h1 class="sc-2c403d08-3 eZUjNd">Crie suas 
      <span style={{color:'#22c55e'}}> rifas</span> em poucos minutos!</h1>
<p>A plataforma completa para você criar e gerenciar suas campanhas com praticidade e facilidade. </p>
        <a href="https://app.eurifei.com/sign-up" rel="noopener noreferrer" aria-label="Criar minha campanha" class="sc-2c403d08-5 uGKWH"><span>Criar minha campanha</span></a>
   </div>
</section>


 </>
    );
  }
}

export default Section1;