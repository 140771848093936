import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./style.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import money from "../../../images/moeda.png"
class Section5 extends React.Component {
  render() {
    return (
 <>
 
 <section class="sc-2c403d08-16 fLOYIJ" style={{background:"#22c55e"}}>
   <div class="sc-2c403d08-8 klLsrI">
      <div class="sc-2c403d08-12 ffgPdB">
         <div>
            <div class="sc-2c403d08-13 gzusM">
               <span className='est1e'>
                  <span className='est2e'>
                    <img className='est3e' alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2764%27%20height=%2764%27/%3e"/>
                    </span>
                <img alt="Emoji de uma pessoa com a boca aberta e dinheiro" src={money} decoding="async" data-nimg="intrinsic" className='est5e'  />
                  <noscript><img alt="Emoji de uma pessoa com a boca aberta e dinheiro" srcSet="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmoney-face.457b7a15.png&w=64&q=75 1x, /_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmoney-face.457b7a15.png&w=128&q=75 2x" src="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmoney-face.457b7a15.png&w=128&q=75" decoding="async" data-nimg="intrinsic" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" loading="lazy"/></noscript>
               </span>
            </div>
         </div>
         <h2>RECEBA SEU DINHEIRO DIRETO NA SUA CONTA</h2>
         
         <a href="https://app.eurifei.com/sign-up" rel="noopener noreferrer" aria-label="Criar minha campanha" class="sc-2c403d08-5 uGKWH"><span>Criar minha campanha</span></a>
      </div>
   </div>
</section>




 </>
    );
  }
}

export default Section5;