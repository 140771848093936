import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import "./termo.css"
import logo from "../../../src/images/logo.png"
import { Link } from 'react-router-dom';
import Footer from '../Inicio/Footer';
import Section8 from '../Inicio/Section8';
class Termos extends React.Component {
   componentDidMount(){
      document.title = "Termos de uso | EuRifei"
   }
   
  render() {
    return (
 <>
 

 <header class="sc-e6341458-6 goCdep">
   <nav aria-label="Menu principal" class="sc-e6341458-7 exeFlu">
      <div class="sc-e6341458-1 bYjJsm header-mobile-menu">
         <ul class="header-navigation">
            <li class="header-navigation-item primary">
               <Link to="/sing-in" aria-label="Login">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-in ">
                        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                        <polyline points="10 17 15 12 10 7"></polyline>
                        <line x1="15" x2="3" y1="12" y2="12"></line>
                     </svg>
                  </div>
                  Login
               </Link>
            </li>
            <li class="header-navigation-item primary">
               <Link to="/sign-up" aria-label="Criar conta">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-plus ">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <line x1="19" x2="19" y1="8" y2="14"></line>
                        <line x1="22" x2="16" y1="11" y2="11"></line>
                     </svg>
                  </div>
                  Criar conta
               </Link>
            </li>
           
            <li class="header-navigation-item">
               <Link aria-label="Sobre nós" to="/perguntas-frequente">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-help-circle ">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <path d="M12 17h.01"></path>
                     </svg>
                  </div>
                  Perguntas frequentes
               </Link>
            </li>
            <li class="header-navigation-item">
               <Link aria-label="Central de ajuda" to="/ajuda">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-life-buoy ">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="m4.93 4.93 4.24 4.24"></path>
                        <path d="m14.83 9.17 4.24-4.24"></path>
                        <path d="m14.83 14.83 4.24 4.24"></path>
                        <path d="m9.17 14.83-4.24 4.24"></path>
                        <circle cx="12" cy="12" r="4"></circle>
                     </svg>
                  </div>
                  Central de ajuda
               </Link>
            </li>
         </ul>
      </div>
      <div class="sc-e6341458-0 lnAbnn header-container">
         <div class="sc-e6341458-11 hUolII">
            <Link aria-label="Página inicial do EuRifei" to="/" class="sc-e6341458-8 gxWuHA">
          EuRifei
            </Link>
            <button type="button" aria-label="Abrir menu" aria-expanded="false" aria-haspopup="true" class="sc-e6341458-3 jWdlOA">
               <div class="sc-e6341458-4 cOGBuu">
                  <div class="sc-e6341458-5 hFrINQ header-menu-line"></div>
                  <div class="sc-e6341458-5 hFrINQ header-menu-line"></div>
               </div>
            </button>
         </div>
         <div class="sc-e6341458-9 iga-dTb">
        
         <Link class="sc-e6341458-10 hOJEzN active" aria-label="Sobre nós" to="/ajuda">Central de ajuda</Link>
      
         </div>
         <div class="sc-e6341458-11 hUolII">
        <a  href="" class="sc-688eee80-0 dDccFD header-sign-up" aria-label="Login"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path></svg>
         WhatsApp
        </a>
    
    <a href="https://eurifei.com" class="sc-688eee80-0 dDccFD header-sign-up" aria-label="Criar conta">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link "><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" x2="21" y1="14" y2="3"></line></svg>
            Acessar eu EuRifei</a></div>
      </div>
   </nav>
</header>


<main class="sc-8ff12269-0 dNyJGa">

<div class="sc-8ff12269-2 eoiGsE"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 67" width="960" version="1.1"><path d="m0 0l22.8 3.7c22.9 3.6 68.5 11 114.2 14.8 45.7 3.8 91.3 4.2 137 6.8 45.7 2.7 91.3 7.7 137.2 7.7 45.8 0 91.8-5 137.6-5.8 45.9-0.9 91.5 2.5 137.2-2 45.7-4.5 91.3-16.9 137-21.5 45.7-4.7 91.3-1.7 114.2-0.2l22.8 1.5v63h-22.8c-22.9 0-68.5 0-114.2 0-45.7 0-91.3 0-137 0-45.7 0-91.3 0-137.2 0-45.8 0-91.8 0-137.6 0-45.9 0-91.5 0-137.2 0-45.7 0-91.3 0-137 0-45.7 0-91.3 0-114.2 0h-22.8z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg></div>
<section class="sc-8ff12269-8 eLcZJa">
   <div class="sc-8ff12269-4 kydPai" style={{marginTop:32}}>
   <main class="sc-5c5ffd7-2 dGLuMS">
   <div class="sc-5c5ffd7-3 ixjzCB">
      <div class="sc-5c5ffd7-4 txzgH">
         <div class="sc-5c5ffd7-5 isSaZY">
            <div class="sc-5c5ffd7-6 SLHoL">
               
            </div>
            <div class="sc-5c5ffd7-8 gsSEJV">
               <h1 class="sc-5c5ffd7-9 hPuPB">Termos de uso</h1>
            </div>
            <div class="sc-5c5ffd7-14 kuEGOh">
               <div class="sc-5c5ffd7-15 liuitd">
                 <div>
                 <p>Antes de usar nossos serviços, leia atentamente os seguintes termos e condições. Ao acessar ou usar eurifei.com, você concorda em seguir estes Termos de Uso. Se não concordar com estes termos, por favor, não use este site.</p>

<h2>1. Uso do Site</h2>
<ul>
    <li>1.1. O conteúdo de eurifei.com é destinado ao uso pessoal. Você não tem permissão para usar o conteúdo para fins comerciais sem autorização por escrito.</li>
    <li>1.2. O uso do site é gratuito, mas certos serviços ou conteúdos podem exigir pagamento ou assinatura.</li>
    <li>1.3. Você concorda em não usar eurifei.com para atividades ilegais ou que infrinjam direitos de terceiros.</li>
</ul>

<h2>2. Conteúdo e Direitos de Propriedade Intelectual</h2>
<ul>
    <li>2.1. Todo conteúdo, incluindo textos, imagens, gráficos, vídeos e outros materiais em eurifei.com, é protegido por direitos autorais e outras leis de propriedade intelectual.</li>
    <li>2.2. Você não pode copiar, modificar, distribuir ou utilizar o conteúdo sem autorização prévia por escrito de eurifei.com.</li>
</ul>

<h2>3. Conta de Usuário</h2>
<ul>
    <li>3.1. Para acessar algumas áreas ou recursos do site, você pode ser solicitado a criar uma conta de usuário.</li>
    <li>3.2. Você é responsável por manter a confidencialidade de suas informações de login e por todas as atividades que ocorrem em sua conta.</li>
    <li>3.3. Eurifei.com reserva-se o direito de cancelar contas a seu critério, especialmente em casos de uso inadequado ou atividades suspeitas.</li>
</ul>



<h2>8. Legislação Aplicável</h2>
<ul>
    <li>8.1. Estes Termos de Uso são regidos pelas leis do Brasil.</li>
    <li>8.2. Qualquer disputa relacionada a estes Termos de Uso será resolvida nos tribunais competentes do Brasil.</li>
</ul>

<p>Se você tiver dúvidas ou preocupações sobre estes Termos de Uso, entre em contato conosco por meio do formulário de contato em eurifei.com. Obrigado por escolher eurifei.com!</p>

                 </div>
               </div>
            </div>
         </div>
        
      </div>
   </div>
</main>

   </div>
</section>

</main>

<Footer/>

 </>
    );
  }
}

export default Termos;